export const countries = [
  {
    id: 38,
    name: "Afghanistan",
  },
  {
    id: 34,
    name: "Algeria",
  },
  {
    id: 45,
    name: "Angola",
  },
  {
    id: 33,
    name: "Argentina",
  },
  {
    id: 3,
    name: "Australia",
  },
  {
    id: 96,
    name: "Austria",
  },
  {
    id: 89,
    name: "Azerbaijan",
  },
  {
    id: 12,
    name: "Bangladesh",
  },
  {
    id: 95,
    name: "Belarus",
  },
  {
    id: 80,
    name: "Belgium",
  },
  {
    id: 76,
    name: "Benin",
  },
  {
    id: 79,
    name: "Bolivia",
  },
  {
    id: 10,
    name: "Brazil",
  },
  {
    id: 104,
    name: "Bulgaria",
  },
  {
    id: 58,
    name: "Burkina Faso",
  },
  {
    id: 77,
    name: "Burundi",
  },
  {
    id: 70,
    name: "Cambodia",
  },
  {
    id: 53,
    name: "Cameroon",
  },
  {
    id: 40,
    name: "Canada",
  },
  {
    id: 122,
    name: "Central African Republic",
  },
  {
    id: 71,
    name: "Chad",
  },
  {
    id: 62,
    name: "Chile",
  },
  {
    id: 4,
    name: "China",
  },
  {
    id: 30,
    name: "Colombia",
  },
  {
    id: 114,
    name: "Congo",
  },
  {
    id: 119,
    name: "Costa Rica",
  },
  {
    id: 54,
    name: "Côte d'Ivoire",
  },
  {
    id: 127,
    name: "Croatia",
  },
  {
    id: 82,
    name: "Cuba",
  },
  {
    id: 85,
    name: "Czech Republic (Czechia)",
  },
  {
    id: 112,
    name: "Denmark",
  },
  {
    id: 84,
    name: "Dominican Republic",
  },
  {
    id: 66,
    name: "Ecuador",
  },
  {
    id: 1,
    name: "Egypt",
  },
  {
    id: 109,
    name: "El Salvador",
  },
  {
    id: 16,
    name: "Ethiopia",
  },
  {
    id: 113,
    name: "Finland",
  },
  {
    id: 24,
    name: "France",
  },
  {
    id: 129,
    name: "Georgia",
  },
  {
    id: 21,
    name: "Germany",
  },
  {
    id: 48,
    name: "Ghana",
  },
  {
    id: 86,
    name: "Greece",
  },
  {
    id: 65,
    name: "Guatemala",
  },
  {
    id: 74,
    name: "Guinea",
  },
  {
    id: 81,
    name: "Haiti",
  },
  {
    id: 91,
    name: "Honduras",
  },
  {
    id: 93,
    name: "Hungary",
  },
  {
    id: 5,
    name: "India",
  },
  {
    id: 8,
    name: "Indonesia",
  },
  {
    id: 20,
    name: "Iran",
  },
  {
    id: 37,
    name: "Iraq",
  },
  {
    id: 121,
    name: "Ireland",
  },
  {
    id: 25,
    name: "Italy",
  },
  {
    id: 15,
    name: "Japan",
  },
  {
    id: 87,
    name: "Jordan",
  },
  {
    id: 63,
    name: "Kazakhstan",
  },
  {
    id: 28,
    name: "Kenya",
  },
  {
    id: 126,
    name: "Kuwait",
  },
  {
    id: 108,
    name: "Kyrgyzstan",
  },
  {
    id: 102,
    name: "Laos",
  },
  {
    id: 106,
    name: "Lebanon",
  },
  {
    id: 120,
    name: "Liberia",
  },
  {
    id: 105,
    name: "Libya",
  },
  {
    id: 52,
    name: "Madagascar",
  },
  {
    id: 61,
    name: "Malawi",
  },
  {
    id: 46,
    name: "Malaysia",
  },
  {
    id: 59,
    name: "Mali",
  },
  {
    id: 124,
    name: "Mauritania",
  },
  {
    id: 14,
    name: "Mexico",
  },
  {
    id: 128,
    name: "Moldova",
  },
  {
    id: 41,
    name: "Morocco",
  },
  {
    id: 47,
    name: "Mozambique",
  },
  {
    id: 50,
    name: "Nepal",
  },
  {
    id: 68,
    name: "Netherlands",
  },
  {
    id: 123,
    name: "New Zealand",
  },
  {
    id: 107,
    name: "Nicaragua",
  },
  {
    id: 56,
    name: "Niger",
  },
  {
    id: 11,
    name: "Nigeria",
  },
  {
    id: 55,
    name: "North Korea",
  },
  {
    id: 116,
    name: "Norway",
  },
  {
    id: 117,
    name: "Oman",
  },
  {
    id: 9,
    name: "Pakistan",
  },
  {
    id: 118,
    name: "Palestine",
  },
  {
    id: 125,
    name: "Panama",
  },
  {
    id: 97,
    name: "Papua New Guinea",
  },
  {
    id: 103,
    name: "Paraguay",
  },
  {
    id: 44,
    name: "Peru",
  },
  {
    id: 17,
    name: "Philippines",
  },
  {
    id: 39,
    name: "Poland",
  },
  {
    id: 88,
    name: "Portugal",
  },
  {
    id: 60,
    name: "Romania",
  },
  {
    id: 13,
    name: "Russia",
  },
  {
    id: 75,
    name: "Rwanda",
  },
  {
    id: 42,
    name: "Saudi Arabia",
  },
  {
    id: 69,
    name: "Senegal",
  },
  {
    id: 98,
    name: "Serbia",
  },
  {
    id: 101,
    name: "Sierra Leone",
  },
  {
    id: 111,
    name: "Singapore",
  },
  {
    id: 115,
    name: "Slovakia",
  },
  {
    id: 72,
    name: "Somalia",
  },
  {
    id: 27,
    name: "South Africa",
  },
  {
    id: 29,
    name: "South Korea",
  },
  {
    id: 83,
    name: "South Sudan",
  },
  {
    id: 31,
    name: "Spain",
  },
  {
    id: 57,
    name: "Sri Lanka",
  },
  {
    id: 35,
    name: "Sudan",
  },
  {
    id: 90,
    name: "Sweden",
  },
  {
    id: 99,
    name: "Switzerland",
  },
  {
    id: 67,
    name: "Syria",
  },
  {
    id: 94,
    name: "Tajikistan",
  },
  {
    id: 26,
    name: "Tanzania",
  },
  {
    id: 22,
    name: "Thailand",
  },
  {
    id: 100,
    name: "Togo",
  },
  {
    id: 78,
    name: "Tunisia",
  },
  {
    id: 19,
    name: "Turkey",
  },
  {
    id: 110,
    name: "Turkmenistan",
  },
  {
    id: 32,
    name: "Uganda",
  },
  {
    id: 36,
    name: "Ukraine",
  },
  {
    id: 92,
    name: "United Arab Emirates",
  },
  {
    id: 23,
    name: "United Kingdom",
  },
  {
    id: 2,
    name: "United States",
  },
  {
    id: 43,
    name: "Uzbekistan",
  },
  {
    id: 51,
    name: "Venezuela",
  },
  {
    id: 18,
    name: "Vietnam",
  },
  {
    id: 49,
    name: "Yemen",
  },
  {
    id: 64,
    name: "Zambia",
  },
  {
    id: 73,
    name: "Zimbabwe",
  },
];