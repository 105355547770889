import React, { useEffect, useState } from "react";
import { Button, Divider, Dropdown, Layout, Menu, Space, Typography } from "antd";
import { DownloadOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Logout } from "../../../actions/Profile";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarContent } from "../../../actions/Sidebar";
import { axiosIntance } from "../../API/Base";

const Header = (props) => {
  const { Title } = Typography;
  const history = useHistory();
  const token = useSelector((state) => state.profile.token);
  const dispatch = useDispatch();

  const [downloadLinks, setDownloadLinks] = useState({
    sidlab: { link: "http://downloads.sidlab.se/sidlab5/SIDLAB511.exe", version: "5.1.1" },
    fnl: { link: "http://downloads.sidlab.se/fnlm5/SIDLAB511_FNLManager.exe", version: "5.1.1" },
    matlab: {
      link: "https://ssd.mathworks.com/supportfiles/downloads/R2023a/Release/5/deployment_files/installer/complete/win64/MATLAB_Runtime_R2023a_Update_5_win64.zip",
      version: "R2023a",
    },
  });

  useEffect(() => {
    axiosIntance.get("versions/getVersions").then((res) => {
      if (res.data.statusCode === "OK") {
        const latestVersionsData = res.data.data.slice(-1)[0];
        if (latestVersionsData.sidlabDownloadLink) {
          setDownloadLinks((prev) => ({
            ...prev,
            sidlab: { link: latestVersionsData.sidlabDownloadLink, version: latestVersionsData.name },
            matlab: { ...prev.matlab, link: latestVersionsData.matlabRuntimeDownloadLink },
            fnl: { link: latestVersionsData.fnlDownloadLink, version: latestVersionsData.name },
          }));
        }
      }
    });
  }, []);

  const handleClick = () => {
    axiosIntance
      .post(`/customers/logout`, null, { params: { token } })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          console.log("Logged Successfully");
        } else {
          console.log("logout error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    history.push("/");
    document.cookie = "token=;";
    localStorage.clear();
    dispatch(Logout());
    dispatch(setSidebarContent(6));
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <a download href={downloadLinks.sidlab.link}>
              SIDLAB {downloadLinks.sidlab.version}
            </a>
          ),
          key: "0",
        },
        {
          label: (
            <a download href={downloadLinks.fnl.link}>
              FNL Manager {downloadLinks.fnl.version}
            </a>
          ),
          key: "1",
        },
        {
          label: (
            <a download href={downloadLinks.matlab.link}>
              MATLAB Runtime Engine
            </a>
          ),
          key: "2",
        },
      ]}
    />
  );

  let content = null;
  if (true) {
    content = (
      <div className="userName">
        {/* download matlab and sidlab button */}
        <Button className="logoutBtn" style={{ width: "13rem" }}>
          <Dropdown overlay={menu} placement="bottomRight" arrow overlayStyle={{ textAlignLast: "center" }}>
            <a href="/" onClick={(e) => e.preventDefault()}>
              <Space>
                Download
                <DownloadOutlined style={{ fontSize: "2rem", color: "#2d3e83" }} />
              </Space>
            </a>
          </Dropdown>
        </Button>
        <Divider type="vertical" style={{ height: "3rem", margin: "0 1rem" }} />
        <Button onClick={handleClick} className="logoutBtn">
          Logout <LogoutOutlined style={{ fontSize: "2rem", color: "#2d3e83" }} />
        </Button>
      </div>
    );
  }

  return (
    <Layout>
      <nav className="header">
        <div className="headerTitle">
          {/* <Avatar className='headerLogo' size="large" src={sidlabIcon} /> */}
          <Title className="headerTitle" style={{ color: "#fff", letterSpacing: "3px", fontWeight: "500" }} level={4}>
            License Manager
          </Title>
        </div>
        {content}
      </nav>
    </Layout>
  );
};

export default Header;
