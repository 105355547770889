import React, { useEffect, useState } from "react";
import { Button, Col, message, Popconfirm, Row, Spin, Tooltip } from "antd";
import "./CustomerViewTable.css";
import { CloseOutlined, EditOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { protectedAxiosIntance } from "../../../API/Base";
import { addTrial } from "../../../../actions/Profile";
import { useDispatch, useSelector } from "react-redux";
import CustomerCourseCodes from "../customer-codes/CustomerCourseCodes";
import { countries as distributionAreas } from "../../../countries";

const CustomerViewTable = ({
  loadingLicenseInfo,
  currentCustomer,
  setCurrentCustomer,
  handleViewCustomer,
  handleEditLicenseForm,
  handleEditPersonalForm,
  handleAddLicenseForm,
  tokenExpired,
  resetSearch,
}) => {
  const viewLicense = (license) => {
    let seats = null;
    if (
      (license.licenseTypeModel.name === "Permanent" || license.licenseTypeModel.name === "Temporary") &&
      license.licenseCategoryModel.name === "FNL"
    ) {
      seats = [
        <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Number of seats
        </Col>,
        <Col key={2} span={15} className="tViewRowCell">
          {license.seats}
        </Col>,
      ];
    } else {
      seats = [];
    }
    let content = null;
    if (license?.licenseTypeModel.name === "Permanent") {
      content = [
        <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Category
        </Col>,
        <Col key={2} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseCategoryModel.name}
        </Col>,
        <Col key={3} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Type
        </Col>,
        <Col key={4} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseTypeModel.name}
        </Col>,
        <Col key={5} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Purchase date
        </Col>,
        <Col key={6} span={15} className="tViewRowCell">
          {license?.purchaseDate}
        </Col>,
        // <Col span={9}  className='tViewRowCell' style={{fontWeight: '500'}}>Subscription years</Col>,
        // <Col span={15} className='tViewRowCell'>{license.subscribtionYears}</Col>,
        <Col key={7} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Renewal date
        </Col>,
        <Col key={8} span={15} className="tViewRowCell">
          {license?.renewDate}
        </Col>,
        <Col key={9} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Subscription Start date
        </Col>,
        <Col key={10} span={15} className="tViewRowCell">
          {license?.licenseSubscriptionModels[0].subscriptionStartDate}
        </Col>,
        <Col key={11} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Subscription End date
        </Col>,
        <Col key={12} span={15} className="tViewRowCell">
          {license?.licenseSubscriptionModels[0].subscriptionEndDate}
        </Col>,
        <Col key={13} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Number of Months
        </Col>,
        <Col key={14} span={15} className="tViewRowCell">
          {license?.licenseSubscriptionModels[0].months}
        </Col>,
        <Col key={15} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Eligible version
        </Col>,
        <Col key={16} span={15} className="tViewRowCell">
          {license?.version}
        </Col>,
        <Col key={17} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Modules
        </Col>,
        <Col key={18} span={15} className="tViewRowCell">
          {license?.moduleModels.map((module) => module.name).join(", ")}
        </Col>,
        <Col key={19} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          MAC addresses
        </Col>,
        <Col key={20} span={15} className="tViewRowCell">
          {license?.macAddresses ? license?.macAddresses.map((m) => m + ", ") : ""}
        </Col>,
        // <Col span={9}  className='tViewRowCell' style={{fontWeight: '500'}}>MATLAB access</Col>,
        // <Col span={15} className='tViewRowCell'>{license.matlabFilesAccess}</Col>,
        [seats],
        // <Col span={9}  className='tViewRowCell' style={{fontWeight: '500'}}>Number of months</Col>,
        // <Col span={15} className='tViewRowCell'>{license.numberOfMonths}</Col>,
      ];
    } else if (license?.licenseTypeModel?.name === "Trial") {
      content = [
        <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Category
        </Col>,
        <Col key={2} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseCategoryModel?.name}
        </Col>,
        <Col key={3} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Type
        </Col>,
        <Col key={4} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseTypeModel?.name}
        </Col>,
        <Col key={5} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Start date
        </Col>,
        <Col key={6} span={15} className="tViewRowCell">
          {license?.startDate}
        </Col>,
        <Col key={7} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          End date
        </Col>,
        <Col key={8} span={15} className="tViewRowCell">
          {license?.endDate}
        </Col>,
        <Col key={9} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Eligible version
        </Col>,
        <Col key={10} span={15} className="tViewRowCell">
          {license?.version}
        </Col>,
        <Col key={11} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Modules
        </Col>,
        <Col key={12} span={15} className="tViewRowCell">
          {license?.moduleModels.map((module) => module?.name).join(", ")}
        </Col>,
        <Col key={13} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          MAC addresses
        </Col>,
        <Col key={14} span={15} className="tViewRowCell">
          {license?.macAddresses ? license?.macAddresses.map((m) => m + ", ") : ""}
        </Col>,
      ];
    } else if (license?.licenseTypeModel?.name === "Student") {
      content = [
        <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Category
        </Col>,
        <Col key={2} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseCategoryModel?.name}
        </Col>,
        <Col key={3} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Type
        </Col>,
        <Col key={4} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseTypeModel?.name}
        </Col>,
        <Col key={5} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Start date
        </Col>,
        <Col key={6} span={15} className="tViewRowCell">
          {license?.startDate}
        </Col>,
        <Col key={7} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          End date
        </Col>,
        <Col key={8} span={15} className="tViewRowCell">
          {license?.endDate}
        </Col>,
        <Col key={9} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Eligible version
        </Col>,
        <Col key={10} span={15} className="tViewRowCell">
          {license?.version}
        </Col>,
        <Col key={11} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          MAC addresses
        </Col>,
        <Col key={12} span={15} className="tViewRowCell">
          {license?.macAddresses ? license?.macAddresses.map((m) => m + ", ") : ""}
        </Col>,
        <Col key={13} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Modules
        </Col>,
        <Col key={14} span={15} className="tViewRowCell">
          {license?.moduleModels.map((module) => module?.name).join(", ")}
        </Col>,
        <Col key={15} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Course code
        </Col>,
        <Col key={16} span={15} className="tViewRowCell">
          {license?.courseCode}
        </Col>,
      ];
    } else if (license?.licenseTypeModel?.name === "Monthly") {
      content = [
        <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Category
        </Col>,
        <Col key={2} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseCategoryModel?.name}
        </Col>,
        <Col span={9} key={3} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Type
        </Col>,
        <Col key={4} span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseTypeModel?.name}
        </Col>,
        <Col span={9} key={5} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Start date
        </Col>,
        <Col key={6} span={15} className="tViewRowCell">
          {license?.startDate}
        </Col>,
        <Col span={9} key={7} className="tViewRowCell" style={{ fontWeight: "500" }}>
          End date
        </Col>,
        <Col key={8} span={15} className="tViewRowCell">
          {license?.endDate}
        </Col>,
        <Col span={9} key={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Months Number
        </Col>,
        <Col key={10} span={15} className="tViewRowCell">
          {license?.months}
        </Col>,
        <Col span={9} key={11} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Remaining Days
        </Col>,
        <Col key={12} span={15} className="tViewRowCell">
          {license?.remainingDays}
        </Col>,
        <Col span={9} key={13} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Eligible version
        </Col>,
        <Col key={14} span={15} className="tViewRowCell">
          {license?.version}
        </Col>,
        <Col span={9} key={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
          MAC addresses
        </Col>,
        <Col key={16} span={15} className="tViewRowCell">
          {license?.macAddresses ? license?.macAddresses.map((m) => m + ", ") : ""}
        </Col>,
        <Col span={9} key={17} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Modules
        </Col>,
        <Col key={18} span={15} className="tViewRowCell">
          {license?.moduleModels.map((module) => module?.name).join(", ")}
        </Col>,
      ];
    } else if (license?.licenseTypeModel?.name === "Temporary") {
      content = [
        <Col span={9} key={1} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Category
        </Col>,
        <Col span={15} key={2} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseCategoryModel.name}
        </Col>,
        <Col span={9} key={3} className="tViewRowCell" style={{ fontWeight: "500" }}>
          License Type
        </Col>,
        <Col span={15} key={4} className="tViewRowCell" style={{ fontWeight: "500" }}>
          {license?.licenseTypeModel.name}
        </Col>,
        <Col span={9} key={5} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Start date
        </Col>,
        <Col span={15} key={6} className="tViewRowCell">
          {license?.startDate}
        </Col>,
        <Col span={9} key={7} className="tViewRowCell" style={{ fontWeight: "500" }}>
          End date
        </Col>,
        <Col span={15} key={8} className="tViewRowCell">
          {license?.endDate}
        </Col>,
        <Col span={9} key={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Eligible version
        </Col>,
        <Col span={15} key={10} className="tViewRowCell">
          {license?.version}
        </Col>,
        <Col span={9} key={11} className="tViewRowCell" style={{ fontWeight: "500" }}>
          Modules
        </Col>,
        <Col span={15} key={12} className="tViewRowCell">
          {license?.moduleModels.map((module) => module.name).join(", ")}
        </Col>,
        <Col span={9} key={13} className="tViewRowCell" style={{ fontWeight: "500" }}>
          MAC addresses
        </Col>,
        <Col span={15} key={14} className="tViewRowCell">
          {license?.macAddresses ? license?.macAddresses.map((m) => m + ", ") : ""}
        </Col>,
        // <Col span={9} key={1}  className='tViewRowCell' style={{fontWeight: '500'}}>MATLAB access</Col>,
        // <Col span={15} className='tViewRowCell'>{license.matlabFilesAccess}</Col>,
        [seats],
        // <Col span={9} key={1}  className='tViewRowCell' style={{fontWeight: '500'}}>Number of months</Col>,
        // <Col span={15} className='tViewRowCell'>{license.numberOfMonths}</Col>,
      ];
    }
    return content;
  };
  const customer = useSelector((state) => state.profile.user.customer);
  const dispatch = useDispatch();
  const adminRole = customer.roles.find((role) => role.name === "Admin");
  const [codes, setCodes] = useState([]);

  const info = () => {
    message.success("The license deleted successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };

  const deleteLicense = (id) => {
    protectedAxiosIntance()
      .delete(`licenses/deleteLicense/${id}`)
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          protectedAxiosIntance()
            .get(`licenses/getCustomerLicenses/${currentCustomer.id}`)
            .then((res) => {
              console.log(res.data.data);
              if (currentCustomer.id === customer.id) {
                dispatch(addTrial(res.data.data));
              }
              setCurrentCustomer({ ...currentCustomer, licenses: res.data.data });
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (res.data.statusCode === "CONFLICT") {
          message.error(res.data.message);
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentCustomer.teacher && currentCustomer.id) {
      protectedAxiosIntance()
        .get(`courses/findCourse/${currentCustomer.id}`)
        .then((res) => {
          setCodes(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentCustomer]);

  useEffect(() => {
    return () => {
      resetSearch();
    };
  }, []);

  return (
    <>
      <Spin tip="Loading..." spinning={loadingLicenseInfo}>
        <LeftCircleOutlined style={{ marginBottom: "1rem", fontSize: "2.5rem", color: "#008acd" }} onClick={handleViewCustomer} />
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            className="scrolling"
            style={{
              width: "100%",
              paddingTop: "2rem",
              marginRight: "2rem",
            }}
          >
            <div className="scrolling">
              <Row className="tViewRowHeader">Personal Information</Row>
              <Row className="tViewRow">
                <Col span={24} className="tViewRowCell" style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <Tooltip placement="top" title="Edit Personal Information">
                    <i style={{ cursor: "pointer", fontSize: "1.4rem" }} onClick={handleEditPersonalForm}>
                      <EditOutlined />
                    </i>
                  </Tooltip>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  First Name
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.firstName}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Last Name
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.lastName}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Email
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.email}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Organization
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.organization}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Country
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.country}
                </Col>
                {currentCustomer.roles && currentCustomer.roles[0]?.name === "Distributor" ? (
                  <>
                    <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                      Distribution Area
                    </Col>
                    <Col span={15} className="tViewRowCell">
                      {currentCustomer.distributionArea
                        ?.map((areaId) => {
                          const filtered = distributionAreas.filter((area) => area.id == areaId)[0];
                          return filtered?.name;
                        })
                        .join(", ")}
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Phone
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.phoneNumber}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Role
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.roles[0] && currentCustomer.roles[0].name}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Request Trial Allowance
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.requestTrial ? "true" : "false"}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Teacher
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.teacher ? "true" : "false"}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Offline License Allowed
                </Col>
                <Col span={15} className="tViewRowCell">
                  {currentCustomer.offlineLicenseAllowed ? "true" : "false"}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Notes:
                </Col>
                <Col span={15} className="tViewRowCell" style={{ fontWeight: "250" }}>
                  {currentCustomer.note}
                </Col>
              </Row>
              <Row className="tViewRowHeader">License Information</Row>
              {currentCustomer?.licenses?.map((license, i) => {
                return (
                  <Row key={i} className="tViewRow">
                    {adminRole && (
                      <Col span={24} className="tViewRowCell" style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Tooltip style={{ cursor: "pointer", fontSize: "1.4rem" }} placement="top" title="delete">
                          <Popconfirm title="Are You Sure You Want to Delete?" onConfirm={() => deleteLicense(license.id)}>
                            <CloseOutlined style={{ marginTop: "0.4rem" }} />
                          </Popconfirm>
                        </Tooltip>
                        <Tooltip placement="top" title="Edit License Information">
                          <i
                            style={{
                              cursor: "pointer",
                              fontSize: "1.4rem",
                              marginRight: "1.5rem",
                            }}
                            onClick={() => {
                              handleEditLicenseForm();
                              setCurrentCustomer({
                                ...currentCustomer,
                                licenseInfo: license,
                              });
                            }}
                          >
                            <EditOutlined />
                          </i>
                        </Tooltip>
                      </Col>
                    )}
                    {viewLicense(license)}
                  </Row>
                );
              })}
            </div>
          </div>

          {currentCustomer.teacher ? (
            <div
              className="scrolling"
              style={{
                width: "55%",
                paddingTop: "2rem",
                marginRight: "1rem",
                // display:'inline-block'
                // height:'50rem', overflowY:'scroll'
              }}
            >
              <CustomerCourseCodes
                adminRole={adminRole}
                customer={currentCustomer}
                codes={codes}
                setCodes={setCodes}
                tokenExpired={tokenExpired}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {adminRole && (
          <Button
            type="primary"
            style={{
              borderRadius: "7px",
              backgroundColor: "#008acd",
              marginRight: "5px",
              marginTop: "1rem",
            }}
            onClick={handleAddLicenseForm}
          >
            Add License
          </Button>
        )}
      </Spin>
    </>
  );
};

export default CustomerViewTable;
