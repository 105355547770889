import React, { useEffect, useState } from "react";
import { Button, Input, message, Spin } from "antd";
import ReleasesTableHeader from "./releases-table/ReleasesTableHeader";
import ReleasesTableRow from "./releases-table/ReleasesTableRow";
import AddReleaseForm from "./releases-table/AddReleaseForm";
import UpdateReleaseForm from "./releases-table/UpdateReleaseForm";
import { axiosIntance, protectedAxiosIntance } from "../../API/Base";
import * as XLSX from "xlsx/xlsx.mjs";
import saveAs from "file-saver";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import styles from "./Releases.module.css";

const Releases = (props) => {
  const { Search } = Input;
  const initial = [
    {
      id: null,
      name: "",
      date: "",
      sidlabDownloadLink: "",
      matlabRuntimeDownloadLink: "",
      fnlDownloadLink: "",
      note: "",
    },
  ];
  const [releasesData, setReleasesData] = useState(initial);

  const [currentRelease, setCurrentRelease] = useState({
    id: null,
    name: "",
    date: "",
    sidlabDownloadLink: "",
    fnlDownloadLink: "",
    matlabRuntimeDownloadLink: "",
    notes: "",
  });

  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [xlsArray, setXlsArray] = useState([["id", "name", "date", "notes"]]);
  const [query, setQuery] = useState("");
  const keys = ["name"];
  const [filteredReleases, setFilteredReleases] = useState(initial);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const customer = useSelector((state) => state.profile.user.customer);
  const adminRole = customer.roles.find((role) => role.name === "Admin");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosIntance
      .get("versions/getVersions")
      .then((res) => {
        if (res.data.statusCode === "OK") {
          setReleasesData(res.data.data);
          setFilteredReleases(res.data.data);
          setLoading(false);
          if (xlsArray.length > 1) {
            let tempXls = [["id", "name", "date", "notes"]];
            setXlsArray(
              tempXls.concat(res.data.data.map((release) => Object.values([release.id, release.name, release.date, release.notes])))
            );
          } else {
            setXlsArray(
              xlsArray.concat(res.data.data.map((release) => Object.values([release.id, release.name, release.date, release.notes])))
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    
  }, [currentRelease, showAddForm, showEditForm]);

  useEffect(() => {
    setFilteredReleases(search(releasesData));
  }, [query]);

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    wb.props = {
      Title: "Releases",
      Author: "SIDLAB Portal",
    };
    wb.SheetNames.push("Sheet1");
    let ws = XLSX.utils.aoa_to_sheet(xlsArray);
    wb.Sheets["Sheet1"] = ws;
    const wbOutput = XLSX.write(wb, { bookType: "csv", type: "binary" });
    saveAs(new Blob([s2ab(wbOutput)], { type: "application/octet-stream" }), "releases.csv");
  };

  const search = (data) => {
    return data.filter(
      (custom) => keys.some((key) => custom[key].toLowerCase().includes(query))
      // custom.email.toLowerCase().includes(query) || custom.name.toLowerCase().includes(query)
    );
  };
  const handleShowAddForm = () => {
    setShowAddForm(!showAddForm);
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const editRow = (release) => {
    setCurrentRelease({
      id: release.id,
      name: release.name,
      date: release.date,
      sidlabDownloadLink: release.sidlabDownloadLink,
      matlabRuntimeDownloadLink: release.matlabRuntimeDownloadLink,
      fnlDownloadLink: release.fnlDownloadLink,
      notes: release.notes,
    });
  };
  const info = () => {
    message.success("The Version deleted successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };

  const updateRelease = (id, updatedRelease) => {
    protectedAxiosIntance()
      .post("versions/updateVersion", {
        id: id,
        name: updatedRelease.name,
        date: updatedRelease.date,
        sidlabDownloadLink: updatedRelease.sidlabDownloadLink,
        matlabRuntimeDownloadLink: updatedRelease.matlabRuntimeDownloadLink,
        fnlDownloadLink: updatedRelease.fnlDownloadLink,
        notes: updatedRelease.notes,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          message.success("Version Updated");
          setCurrentRelease({
            id: null,
            name: "",
            date: "",
            sidlabDownloadLink: "",
            matlabRuntimeDownloadLink: "",
            fnlDownloadLink: "",
            notes: "",
          });
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRelease = (id) => {
    protectedAxiosIntance()
      .delete(`versions/deleteVersion/${id}`)
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          setCurrentRelease({
            id: null,
            name: "",
            date: "",
            sidlabDownloadLink: "",
            matlabRuntimeDownloadLink: "",
            fnlDownloadLink: "",
            notes: "",
          });
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function compare(a, b) {
    if (moment(a.date, dateFormatList[0]) - moment(b.date, dateFormatList[0]) < 0) {
      return 1;
    }
    if (moment(a.date, dateFormatList[0]) - moment(b.date, dateFormatList[0]) > 0) {
      return -1;
    }
    return 0;
  }

  let content = null;
  if (showAddForm) {
    content = <AddReleaseForm handleShowAddForm={handleShowAddForm} tokenExpired={props.tokenExpired} />;
  } else if (showEditForm) {
    content = <UpdateReleaseForm currentRelease={currentRelease} updateRelease={updateRelease} handleShowEditForm={handleShowEditForm} />;
  } else {
    content = [
      <Search
        key={1}
        placeholder="input search text"
        style={{ width: 200, marginBottom: "1rem" }}
        onChange={(e) => setQuery(e.target.value)}
      />,
      adminRole && (
        <Button
          key={2}
          style={{ backgroundColor: "#008acd", borderRadius: "7px", marginRight: "1rem", marginLeft: "1rem" }}
          type="primary"
          onClick={handleShowAddForm}
        >
          Add
        </Button>
      ),
      <Button key={3} type="primary" onClick={handleExport} style={{ backgroundColor: "#008acd", borderRadius: "7px", marginLeft: "1rem" }}>
        Export
      </Button>,
      <ReleasesTableHeader adminRole={adminRole} key={4} />,
      <Spin
        key={5}
        spinning={loading}
        tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}
        size="large"
        className={styles.spinner}
      >
        {filteredReleases.sort(compare).map((release, i) => {
          return (
            <ReleasesTableRow
              key={i}
              id={release.id}
              release={release}
              name={release.name}
              date={release.date}
              sidlabDownloadLink={release.sidlabDownloadLink}
              fnlDownloadLink={release.fnlDownloadLink}
              matlabRuntimeDownloadLink={release.matlabRuntimeDownloadLink}
              notes={release.notes}
              adminRole={adminRole}
              deleteRelease={deleteRelease}
              handleShowAddForm={handleShowAddForm}
              handleShowEditForm={handleShowEditForm}
              editRow={editRow}
            />
          );
        })}
      </Spin>,
    ];
  }

  return (
    <div className="scrolling" style={{ width: "100%", marginTop: "5rem", overflowY: "scroll" }}>
      {content}
    </div>
  );
};

export default Releases;
