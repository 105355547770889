import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Spin, Switch } from "antd";
import "../../../required.css";
import { axiosIntance, protectedAxiosIntance } from "../../../API/Base";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { setProfile } from "../../../../actions/Profile";
import moment from "moment/moment";
import { countries } from "../../../countries";

const { Option } = Select;

const UpdateCustomersForm = (props) => {
  const [hideDisArea, setHideDisArea] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(props.currentCustomer);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [form] = Form.useForm();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const [roles, setRoles] = useState([]);
  const [versions, setVersions] = useState([]);
  const [allowTrial, setAllowTrial] = useState(currentCustomer.requestTrial);
  const [teacher, setTeacher] = useState(currentCustomer.teacher);
  const [allowOffline, setAllowOffline] = useState(currentCustomer.offlineLicenseAllowed);
  const loggedCustomer = useSelector((state) => state.profile.user.customer);
  const [tags, setTags] = useState(currentCustomer.licenseInfo?.macAddresses ? currentCustomer.licenseInfo?.macAddresses : []);
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const setInitial = () => {
    if (props.editLicenseForm) {
      if (currentCustomer.licenseInfo.licenseTypeModel.name === "Permanent") {
        return {
          id: currentCustomer.id,
          versionId: currentCustomer.licenseInfo.versionId,
          seats: currentCustomer.licenseInfo.seats,
          purchaseDatePer: currentCustomer.licenseInfo.purchaseDate,
          subscriptionStartDate: currentCustomer.licenseInfo.licenseSubscriptionModels[0].subscriptionStartDate,
          subscriptionEndDate: currentCustomer.licenseInfo.licenseSubscriptionModels[0].subscriptionEndDate,
          monthsPer: currentCustomer.licenseInfo.licenseSubscriptionModels[0].months,
          macAddresses: tags,
          modulesPer: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        };
      } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Monthly") {
        return {
          id: currentCustomer.id,
          versionId: currentCustomer.licenseInfo.versionId,
          monthsMon: currentCustomer.licenseInfo.months,
          startDate: currentCustomer.licenseInfo.startDate,
          endDate: currentCustomer.licenseInfo.endDate,
          remainingDays: currentCustomer.licenseInfo.remainingDays,
          macAddresses: tags,
          modulesMon: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        };
      } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Trial") {
        return {
          id: currentCustomer.id,
          versionId: currentCustomer.licenseInfo.versionId,
          startDate: currentCustomer.licenseInfo.startDate,
          endDate: currentCustomer.licenseInfo.endDate,
          macAddresses: tags,
          modulesTrial: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        };
      } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Student") {
        return {
          id: currentCustomer.id,
          versionId: currentCustomer.licenseInfo.versionId,
          courseCode: currentCustomer.licenseInfo.courseCode,
          previousCode: currentCustomer.licenseInfo.courseCode,
          macAddresses: tags,
        };
      } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Temporary") {
        return {
          id: currentCustomer.id,
          versionId: currentCustomer.licenseInfo.versionId,
          seats: currentCustomer.licenseInfo.seats,
          startDate: currentCustomer.licenseInfo.startDate,
          endDate: currentCustomer.licenseInfo.endDate,
          macAddresses: tags,
          modulesPer: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        };
      }
    } else {
      return {
        id: currentCustomer.id,
        firstName: currentCustomer.firstName,
        lastName: currentCustomer.lastName,
        email: currentCustomer.email,
        organization: currentCustomer.organization,
        countryName: currentCustomer.country,
        countryId: currentCustomer.countryId,
        phone: currentCustomer.phoneNumber,
        rolesId: [currentCustomer.roles[0].id],
        teacher: currentCustomer.teacher,
        requestTrial: currentCustomer.requestTrial,
        distributionArea: currentCustomer.distributionArea,
        note: currentCustomer.note,
        noteId: currentCustomer.noteId,
      };
    }
  };
  const [customer, setCustomer] = useState(setInitial());

  useEffect(() => {
    if (customer.rolesId && customer.rolesId[0] !== 7) {
      setHideDisArea(true);
    } else if (customer.rolesId && customer.rolesId[0] === 7) {
      setHideDisArea(false);
    }
  }, [customer]);

  useEffect(() => {
    axiosIntance
      .get("/versions/getVersions")
      .then((res) => {
        if (res.data.statusCode === "OK") setVersions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    protectedAxiosIntance()
      .get("roles/getRoles")
      .then((res) => setRoles(res.data.data))
      .catch((err) => {
        console.log(err);
      });
    return () => {
      props.resetSearch();
    };
  }, []);

  const disabledDate = (current) => {
    return customer.purchaseDatePer !== "" ? current && current < moment(customer.purchaseDatePer, "DD/MM/YYYY") : true;
  };

  const handleSimpleDateChange = (name, date, dateString) => {
    setCustomer({ ...customer, [name]: dateString });
  };

  const handleChange = (name, value) => {
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };
  const handleDateChange = (prop, endDate, date, dateString) => {
    prop.map((property) => {
      customer[property] = dateString;
    });
    customer.subscriptionEndDate = endDate;
    form.setFieldValue("subStartDatePer", date);
    form.setFieldValue("subscriptionEndDate", customer.subscriptionEndDate);
    setCustomer({ ...customer, subscriptionEndDate: endDate });
  };

  const handleMonthNum = (name, value) => {
    let moment1 = moment(customer.subscriptionStartDate, "DD/MM/YYYY");
    let newDate = new Date(moment1._d);
    let numberOfMonthAdded = newDate.getDate() === 1 ? 0 : 1;
    newDate.setMonth(newDate.getMonth() + parseInt(value) + numberOfMonthAdded);
    newDate.setDate(1);
    customer.subscriptionEndDate = newDate.toLocaleDateString("en-GB");
    form.setFieldValue("subscriptionEndDate", customer.subscriptionEndDate);
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };
  const handleMonthlyDateChange = (prop, endDate, date, dateString) => {
    prop.map((property) => {
      customer[property] = dateString;
    });
    customer.endDate = endDate;
    form.setFieldValue("endDateMon", customer.endDate);
    setCustomer({ ...customer, endDate: endDate });
  };
  const handleDaysChange = (name, value) => {
    let moment1 = moment(customer.startDate, "DD/MM/YYYY");
    let start = new Date(moment1._d);
    let newDate = new Date();
    const diffTime = newDate - start;
    if (diffTime > 0) {
      newDate.setDate(newDate.getDate() + parseInt(value));
    } else {
      newDate.setDate(start.getDate() + parseInt(value));
    }
    customer.endDate = newDate.toLocaleDateString("en-GB");
    form.setFieldValue("endDateMon", customer.endDate);
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };

  // helper function to check if mac address is valid
  const validMac = (mac_address) => {
    return /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/g.test(mac_address);
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const updateInput = (e) => {
    const currentInputValue = e.target.value.replace(/[^0-9A-Fa-f:,]/g, "").toUpperCase();
    let values = currentInputValue.split(",");

    values = values.map((macAddress) => {
      let cleaned = macAddress.replace(/[^0-9A-Fa-f]/g, "");
      if (cleaned.length > 12) cleaned = cleaned.substr(0, 12);

      let matched = cleaned.match(/.{1,2}/g);
      return matched ? matched.join(":") : "";
    });

    // clear the error message if every mac address being entered is valid
    if (values.every((macAddress) => macAddress === "" || validMac(macAddress))) {
      setError("");
    }

    setInput(values.join(","));
  };

  const addTag = (tag) => {
    const tagArray = tag
      .split(",")
      .map((t) => t.trim())
      .filter(Boolean);

    if (tagArray.some((t) => !validMac(t))) {
      setError(`Please enter a valid MAC address.`);
    } else {
      setTags([...tags, ...tagArray]);
      setInput("");
    }
  };

  const checkEnter = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (input.length > 0) {
        addTag(input);
      }
    }
  };
  const onSubmit = () => {
    //add api for adding customer here
    message.success("Customer Added Successfully");
  };

  const info = () => {
    message.success("The License updated successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };
  const info2 = () => {
    message.success("The Customer updated successfully");
  };

  const onFinish = () => {
    if (props.editPersonalForm) {
      setButtonDisabled(true);
      protectedAxiosIntance()
        .post("customers/set-note", {
          id: currentCustomer.noteId,
          customerId: currentCustomer.id,
          note: customer.note,
        })
        .then(() => {
          if (props.isCustomer !== "Sales") {
            protectedAxiosIntance()
              .post(`customers/updateCustomer`, {
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                organization: customer.organization,
                phoneNumber: customer.phone,
                countryId: customer.countryId,
                rolesId: customer.rolesId,
                isTeacher: teacher,
                allowTrial: allowTrial,
                offlineLicenseAllowed: allowOffline,
                distributionArea: customer.distributionArea,
              })
              .then((res) => {
                setButtonDisabled(false);
                if (res.data.statusCode === "OK") {
                  info2();
                  if (currentCustomer.id === loggedCustomer.id) {
                    protectedAxiosIntance()
                      .post(`customers/getCustomerData`, {
                        id: currentCustomer.id,
                        email: currentCustomer.email,
                      })
                      .then((res) => {
                        dispatch(setProfile(res.data.data));
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                  props.editRow(customer.id);
                  props.handleShowEditForm();
                } else {
                  info1();
                }
              })
              .catch((err) => {
                setButtonDisabled(false);
                console.log(err);
              });
          } else {
            setButtonDisabled(false);
            info2();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          }
        })
        .catch((err) => {
          setButtonDisabled(false);
          console.log(err);
          message.error("unable to update customer");
        });
    } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Permanent") {
      protectedAxiosIntance()
        .post(`licenses/updateLicense`, {
          id: currentCustomer.licenseInfo.id,
          customerId: currentCustomer.id,
          version: customer.versionId,
          seats: customer.seats,
          purchaseDate: customer.purchaseDatePer,
          subscriptions: [
            {
              months: customer.monthsPer,
              subscriptionStartDate: customer.subscriptionStartDate,
              subscriptionEndDate: customer.subscriptionEndDate,
              licenseId: currentCustomer.licenseInfo.id,
            },
          ],
          macAddresses: !tags || tags.length === 0 ? null : tags,
          modules: customer.modulesPer,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Monthly") {
      protectedAxiosIntance()
        .post(`licenses/updateLicense`, {
          id: currentCustomer.licenseInfo.id,
          customerId: currentCustomer.id,
          version: customer.versionId,
          startDate: customer.startDate,
          endDate: customer.endDate,
          remainingDays: customer.remainingDays,
          macAddresses: !tags || tags.length === 0 ? null : tags,
          modules: customer.modulesMon,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Trial") {
      protectedAxiosIntance()
        .post(`licenses/updateLicense`, {
          id: currentCustomer.licenseInfo.id,
          customerId: currentCustomer.id,
          version: customer.versionId,
          startDate: customer.startDate,
          endDate: customer.endDate,
          macAddresses: !tags || tags.length === 0 ? null : tags,
          modules: customer.modulesTrial,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Student") {
      protectedAxiosIntance()
        .post(`licenses/updateLicense`, {
          id: currentCustomer.licenseInfo.id,
          customerId: currentCustomer.id,
          version: customer.versionId,
          courseCode: customer.courseCode,
          previousCourseCode: customer.previousCode,
          macAddresses: !tags || tags.length === 0 ? null : tags,
          modules: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          } else if (res.data.message === "Course Code Not Found") {
            message.error("The code you entered is not valid, please try another code");
          } else if (res.data.message === "Course already requested") {
            message.error("You have a student license generated with this Student code, please enter another code and try again");
          } else if (res.data.statusCode === "LOCKED") {
            message.error(res.data.message);
          } else if (res.data.message === "Maximum number exceeded") {
            message.error("The maximum students that can use this code is reached, please contact your teacher to get another code");
          } else if (res.data.message === "This is Your Course") {
            message.error("You can not request his own course");
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Temporary") {
      protectedAxiosIntance()
        .post(`licenses/updateLicense`, {
          id: currentCustomer.licenseInfo.id,
          customerId: currentCustomer.id,
          version: customer.versionId,
          startDate: customer.startDate,
          endDate: customer.endDate,
          seats: customer.seats,
          macAddresses: !tags || tags.length === 0 ? null : tags,
          modules: customer.modulesPer,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (currentCustomer.id === loggedCustomer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: currentCustomer.id,
                  email: currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            props.editRow(customer.id);
            props.handleShowEditForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let content;

  let seats = null;
  if (props.editLicenseForm) {
    if (currentCustomer.licenseInfo.licenseCategoryModel?.name === "FNL") {
      seats = [
        <Form.Item
          name="seats"
          rules={[
            {
              required: true,
              message: "Please input your number of seats",
            },
          ]}
        >
          <label style={{ marginRight: "2rem" }}>Number of Seats:</label>
          <InputNumber
            min={1}
            name="seats"
            placeholder="Number of Seats"
            defaultValue={currentCustomer.licenseInfo.seats}
            onChange={(e) => {
              handleChange("seats", e);
            }}
          />
        </Form.Item>,
      ];
    } else {
      seats = [];
    }
  }
  if (props.editPersonalForm) {
    content = [
      <span key={1} style={{ fontWeight: "500" }}>
        First Name
      </span>,

      <Form.Item
        key={2}
        name="firstName"
        rules={[
          {
            required: true,
            message: "Please input customer first name",
          },
          {
            pattern: "^[a-zA-Z ]*$",
            message: "The input is not valid Name!",
          },
        ]}
      >
        <Input
          disabled={!(props.isCustomer === "Admin")}
          name="firstName"
          allowClear
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          placeholder="first name"
        />
      </Form.Item>,
      <span key={3} style={{ fontWeight: "500" }}>
        Last Name
      </span>,

      <Form.Item
        key={4}
        name="lastName"
        rules={[
          {
            required: true,
            message: "Please input customer last name",
          },
          {
            pattern: "^[a-zA-Z ]*$",
            message: "The input is not valid Name!",
          },
        ]}
      >
        <Input
          disabled={!(props.isCustomer === "Admin")}
          name="lastName"
          allowClear
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          placeholder="last name"
        />
      </Form.Item>,

      <span key={5} style={{ fontWeight: "500" }}>
        Organization
      </span>,

      <Form.Item
        key={6}
        name="organization"
        rules={[
          {
            required: true,
            message: "Please input customer organization name",
          },
        ]}
      >
        <Input
          disabled={!(props.isCustomer === "Admin")}
          name="organization"
          placeholder="Organization"
          allowClear
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Form.Item>,

      <span key={7} style={{ fontWeight: "500" }}>
        Country
      </span>,
      <Form.Item
        key={8}
        name="country"
        rules={[
          {
            required: true,
            message: "Please input customer country",
          },
        ]}
      >
        <Select
          disabled={!(props.isCustomer === "Admin")}
          showSearch
          name="country"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("countryId", e);
          }}
          optionFilterProp="children"
          placeholder="Country"
        >
          {countries.map((country, i) => {
            return (
              <Option key={i} value={country.id}>
                {country.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,

      <span key={9} style={{ fontWeight: "500" }}>
        Phone
      </span>,

      <Form.Item
        key={10}
        name="phone"
        rules={[
          {
            required: true,
            message: "Please input your phone number",
          },
        ]}
      >
        <Input
          disabled={!(props.isCustomer === "Admin")}
          inputMode="numeric"
          type={"number"}
          name="phone"
          placeholder="Phone"
          allowClear
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Form.Item>,
      <span key={11} style={{ fontWeight: "500" }}>
        Email
      </span>,
      <Form.Item key={12} name="email">
        <Input name="email" disabled placeholder="Email" />
      </Form.Item>,
      <span key={12.1} style={{ fontWeight: "500" }}>
        Note
      </span>,
      <Form.Item key={12.2} name="note">
        <Input name="note" placeholder="Note" allowClear onChange={(e) => handleChange(e.target.name, e.target.value)} />
      </Form.Item>,

      <div key={13}>
        {props.isCustomer === "Admin" && (
          <>
            <span style={{ fontWeight: "500" }}>Role</span>
            <Form.Item
              name="roles"
              rules={[
                {
                  required: true,
                  message: "Please input customer role",
                },
              ]}
            >
              <Select
                name="roles"
                style={{ width: "100%" }}
                onChange={(e) => {
                  handleChange("rolesId", [e]);
                  if (e !== 7) {
                    setHideDisArea(true);
                  } else {
                    setHideDisArea(false);
                  }
                }}
                placeholder="Role"
              >
                {roles.map((role) => {
                  return (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div style={{ display: `${hideDisArea ? "none" : ""}` }}>
              <span style={{ fontWeight: "500" }}>Distribution Area</span>
              <Form.Item
                name="distributionArea"
                rules={[
                  {
                    required: customer.rolesId == 7,
                    message: "Please Add Distribution Area",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  name="distributionArea"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleChange("distributionArea", e);
                  }}
                  optionFilterProp="children"
                  placeholder="Area"
                >
                  {countries.map((distributionArea, i) => {
                    return (
                      <Option key={i} value={distributionArea.id}>
                        {distributionArea.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div style={{ display: "flex" }}>
              <Form.Item label={"Allow Trial"}>
                <Switch checked={allowTrial} onChange={setAllowTrial} />
              </Form.Item>
              <span style={{ marginRight: "8rem" }}></span>
              <Form.Item label={"Teacher"}>
                <Switch checked={teacher} onChange={setTeacher} />
              </Form.Item>
              <span style={{ marginRight: "8rem" }}></span>
              <Form.Item label={"Allow Offline License"}>
                <Switch checked={allowOffline} onChange={setAllowOffline} />
              </Form.Item>
            </div>
          </>
        )}
      </div>,
      <div key={14} style={{ display: "flex" }}>
        <Form.Item>
          <Button
            disabled={isButtonDisabled}
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Permanent") {
    content = [
      <span key={1} style={{ fontWeight: "500" }}>
        Version
      </span>,
      <Form.Item key={2} name="version">
        <Select
          defaultValue={currentCustomer.licenseInfo.versionId}
          name="version"
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={(e) => {
            handleChange("versionId", e);
          }}
          placeholder="version"
        >
          {props.versions.map((version) => {
            return (
              <Option value={version.id} key={version.id}>
                {version.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
      <span key={3} style={{ fontWeight: "500" }}>
        Purchase date
      </span>,
      <Form.Item
        key={4}
        name="purchaseDatePer"
        rules={[
          {
            required: true,
            message: "Please input purchase date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="purchaseDatePer"
          placeholder="Purchase date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              handleSimpleDateChange("purchaseDatePer", e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span key={5} style={{ fontWeight: "500" }}>
        Subscription Start date
      </span>,
      <Form.Item
        key={6}
        name="subStartDatePer"
        rules={[
          {
            required: true,
            message: "Please input subscription start date",
          },
        ]}
        validateStatus={`${
          moment(customer.subscriptionStartDate, "DD/MM/YYYY") >= moment(customer.purchaseDatePer, "DD/MM/YYYY") ||
          customer.purchaseDatePer === "" ||
          customer.purchaseDatePer === null ||
          customer.subscriptionStartDate === "" ||
          customer.subscriptionStartDate === null
            ? ""
            : "error"
        }`}
        help={
          moment(customer.subscriptionStartDate, "DD/MM/YYYY") < moment(customer.purchaseDatePer, "DD/MM/YYYY")
            ? "Subscription start date should be after purchase date"
            : ""
        }
      >
        <DatePicker
          allowClear
          name="subStartDatePer"
          placeholder="Subscription Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              let newDate = new Date(e._d);
              let numberOfMonthAdded = e.date() === 1 ? 0 : 1;
              newDate.setMonth(e.month() + parseInt(customer.monthsPer) + numberOfMonthAdded);
              newDate.setDate(1);
              handleDateChange(["subscriptionStartDate"], newDate.toLocaleDateString("en-GB"), e, e.format("DD/MM/YYYY"));
            }
          }}
          disabledDate={disabledDate}
        />
      </Form.Item>,
      <span key={7} style={{ fontWeight: "500" }}>
        Subscription End date
      </span>,

      <Form.Item key={8} name="subscriptionEndDate">
        <Input
          disabled
          name="subscriptionEndDate"
          placeholder="Subscription End Date"
          style={{ width: "100%" }}
          type="text"
          value={customer.subscriptionEndDate}
        />
      </Form.Item>,

      <Form.Item
        key={9}
        name="monthsPer"
        rules={[
          {
            required: true,
            message: "Please input your number of months",
          },
        ]}
      >
        <label key={10} style={{ marginRight: "2rem" }}>
          Number of Months:
        </label>
        <InputNumber
          key={11}
          min={1}
          name="monthsPer"
          placeholder="Number of Months"
          defaultValue={customer.monthsPer}
          onChange={(e) => {
            handleMonthNum("monthsPer", e);
          }}
        />
      </Form.Item>,
      <span key={12} style={{ fontWeight: "500" }}>
        Mac Addresses
      </span>,
      <Form.Item key={13} name="macAddresses">
        <div style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid #000",
              padding: "5px",
            }}
          >
            {tags.map((tag, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#D5D5D5",
                  borderRadius: "15px",
                  margin: "5px",
                  paddingLeft: "10px",
                }}
              >
                <div>{tag}</div>
                <CloseCircleOutlined style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => removeTag(i)} />
              </div>
            ))}
            <Input
              style={{ flex: "1 0 auto", margin: "5px", padding: "2px" }}
              type="text"
              value={input}
              onChange={updateInput}
              onKeyDown={checkEnter}
            />
          </div>
          <div>{tags.length} MAC Address(es) added</div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </Form.Item>,

      [seats],

      <span key={14} style={{ fontWeight: "500" }}>
        Purchased Modules
      </span>,

      <Form.Item
        key={15}
        name="modulesPer"
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesPer"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesPer", e);
          }}
          allowClear
        >
          {props.modules.map((module, i) => {
            return (
              <Option key={i} value={module.id}>
                {module.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,

      <div key={16} style={{ display: "flex" }}>
        <Form.Item shouldUpdate>
          <Button
            disabled={
              isButtonDisabled || moment(customer.subscriptionStartDate, "DD/MM/YYYY") < moment(customer.purchaseDatePer, "DD/MM/YYYY")
            }
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Monthly") {
    content = [
      <span style={{ fontWeight: "500" }}>Version</span>,
      <Form.Item name="version">
        <Select
          defaultValue={currentCustomer.licenseInfo.version}
          name="version"
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={(e) => {
            handleChange("versionId", e);
          }}
          placeholder="version"
        >
          {props.versions.map((version) => {
            return (
              <Option value={version.id} key={version.id}>
                {version.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>Start date</span>,
      <Form.Item
        name="startDateMon"
        rules={[
          {
            required: true,
            message: "Please input Start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="startDateMon"
          placeholder="Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              let newDate = new Date(e._d);
              newDate.setDate(e.date() + parseInt(customer.remainingDays));
              handleMonthlyDateChange(["startDate"], newDate.toLocaleDateString("en-GB"), e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>End date</span>,

      <Form.Item name="endDateMon">
        <Input
          disabled
          name="endDateMon"
          placeholder="End Date"
          style={{ width: "100%" }}
          // ref={ref}
          type="text"
          value={customer.endDate}
        />
      </Form.Item>,

      <Form.Item
        name="remainingDays"
        rules={[
          {
            required: true,
            message: "Please input your number of remaining days",
          },
        ]}
      >
        <label style={{ marginRight: "2rem" }}>Remaining Days:</label>
        <InputNumber
          min={1}
          name="seats"
          placeholder="Remaining Days"
          defaultValue={currentCustomer.licenseInfo.remainingDays}
          onChange={(e) => {
            handleDaysChange("remainingDays", e);
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>MAC Addresses</span>,
      <Form.Item name="macAddresses">
        <div key={13} style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid #000",
              padding: "5px",
            }}
          >
            {tags.map((tag, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#D5D5D5",
                  borderRadius: "15px",
                  margin: "5px",
                  paddingLeft: "10px",
                }}
              >
                <div>{tag}</div>
                <CloseCircleOutlined style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => removeTag(i)} />
              </div>
            ))}
            <Input
              style={{ flex: "1 0 auto", margin: "5px", padding: "2px" }}
              type="text"
              value={input}
              onChange={updateInput}
              onKeyDown={checkEnter}
            />
          </div>
          <div>{tags.length} MAC Address(es) added</div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </Form.Item>,

      <span style={{ fontWeight: "500" }}>Purchased Modules</span>,

      <Form.Item
        name="modulesMon"
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesMon"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesMon", e);
          }}
          allowClear
        >
          {props.modules.map((module) => {
            return <Option value={module.id}>{module.name}</Option>;
          })}
        </Select>
      </Form.Item>,
      <div style={{ display: "flex" }}>
        <Form.Item>
          <Button
            disabled={isButtonDisabled}
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Trial") {
    content = [
      <span style={{ fontWeight: "500" }}>Version</span>,
      <Form.Item name="version">
        <Select
          defaultValue={currentCustomer.licenseInfo.version}
          name="version"
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={(e) => {
            handleChange("versionId", e);
          }}
          placeholder="version"
        >
          {props.versions.map((version) => {
            return (
              <Option value={version.id} key={version.id}>
                {version.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>Start date</span>,
      <Form.Item
        name="startDateTrial"
        rules={[
          {
            required: true,
            message: "Please input Start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="startDateTrial"
          placeholder="Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              handleSimpleDateChange("startDate", e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>End date</span>,

      <Form.Item name="endDateTrial">
        <DatePicker
          allowClear
          name="endDateTrial"
          placeholder="End Date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              handleSimpleDateChange("endDate", e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>MAC Addresses</span>,
      <Form.Item name="macAddresses">
        <div key={13} style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid #000",
              padding: "5px",
            }}
          >
            {tags.map((tag, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#D5D5D5",
                  borderRadius: "15px",
                  margin: "5px",
                  paddingLeft: "10px",
                }}
              >
                <div>{tag}</div>
                <CloseCircleOutlined style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => removeTag(i)} />
              </div>
            ))}
            <Input
              style={{ flex: "1 0 auto", margin: "5px", padding: "2px" }}
              type="text"
              value={input}
              onChange={updateInput}
              onKeyDown={checkEnter}
            />
          </div>
          <div>{tags.length} MAC Address(es) added</div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>Purchased Modules</span>,

      <Form.Item
        name="modulesTrial"
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesTrial"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesTrial", e);
          }}
          allowClear
        >
          {props.modules.map((module) => {
            return (
              <Option key={module.id} value={module.id}>
                {module.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
      <div style={{ display: "flex" }}>
        <Form.Item>
          <Button
            disabled={isButtonDisabled}
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Student") {
    content = [
      <span style={{ fontWeight: "500" }}>Course Code</span>,
      <Form.Item
        name="courseCode"
        rules={[
          {
            required: true,
            message: "Please input course code",
          },
        ]}
      >
        <Input
          name="courseCode"
          placeholder="Course Code"
          allowClear
          //defaultValue={12}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
      </Form.Item>,
      <div style={{ display: "flex" }}>
        <Form.Item>
          <Button
            disabled={isButtonDisabled}
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  } else if (props.editLicenseForm && currentCustomer.licenseInfo.licenseTypeModel.name === "Temporary") {
    content = [
      <span style={{ fontWeight: "500" }}>Version</span>,
      <Form.Item name="version">
        <Select
          defaultValue={currentCustomer.licenseInfo.versionId}
          name="version"
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={(e) => {
            handleChange("versionId", e);
          }}
          placeholder="version"
        >
          {props.versions.map((version) => {
            return (
              <Option value={version.id} key={version.id}>
                {version.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,

      <span style={{ fontWeight: "500" }}>Start date</span>,
      <Form.Item
        name="startDateTemp"
        rules={[
          {
            required: true,
            message: "Please input Start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="startDateTemp"
          placeholder="Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              handleSimpleDateChange("startDate", e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>End date</span>,

      <Form.Item name="endDateTemp">
        <DatePicker
          allowClear
          name="endDateTemp"
          placeholder="End Date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              handleSimpleDateChange("endDate", e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>MAC Addresses</span>,
      <Form.Item key={11} name="macAddresses">
        <div key={13} style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              border: "1px solid #000",
              padding: "5px",
            }}
          >
            {tags.map((tag, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#D5D5D5",
                  borderRadius: "15px",
                  margin: "5px",
                  paddingLeft: "10px",
                }}
              >
                <div>{tag}</div>
                <CloseCircleOutlined style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => removeTag(i)} />
              </div>
            ))}
            <Input
              style={{ flex: "1 0 auto", margin: "5px", padding: "2px" }}
              type="text"
              value={input}
              onChange={updateInput}
              onKeyDown={checkEnter}
            />
          </div>
          <div>{tags.length} MAC Address(es) added</div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </Form.Item>,

      [seats],

      <span style={{ fontWeight: "500" }}>Purchased Modules</span>,

      <Form.Item
        name="modulesPer"
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesPer"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesPer", e);
          }}
          allowClear
        >
          {props.modules.map((module) => {
            return <Option value={module.id}>{module.name}</Option>;
          })}
        </Select>
      </Form.Item>,
      <div style={{ display: "flex" }}>
        <Form.Item>
          <Button
            disabled={isButtonDisabled}
            style={{
              paddingRight: "3rem",
              paddingLeft: "3rem",
              borderRadius: "5px",
              backgroundColor: "#008acd",
            }}
            type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Updating..." : "Update Customer"}
          </Button>
        </Form.Item>
        ,
        <Form.Item>
          <Button style={{ marginLeft: "5px" }} onClick={props.handleShowEditForm}>
            Cancel
          </Button>
        </Form.Item>
      </div>,
    ];
  }

  let editForm;
  if (props.editPersonalForm) {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          firstName: currentCustomer.firstName,
          lastName: currentCustomer.lastName,
          organization: currentCustomer.organization,
          country: currentCustomer.country,
          phone: currentCustomer.phoneNumber,
          roles: currentCustomer.roles[0].name,
          email: currentCustomer.email,
          distributionArea: currentCustomer.distributionArea,
          note: currentCustomer.note,
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Permanent") {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          version: currentCustomer.licenseInfo.versionId,
          purchaseDatePer: moment(currentCustomer.licenseInfo.purchaseDate, dateFormatList[0]),
          subStartDatePer: moment(currentCustomer.licenseInfo.licenseSubscriptionModels[0].subscriptionStartDate, dateFormatList[0]),
          subscriptionEndDate: currentCustomer.licenseInfo.licenseSubscriptionModels[0].subscriptionEndDate,
          monthsPer: currentCustomer.licenseInfo.licenseSubscriptionModels[0].months,
          seats: currentCustomer.licenseInfo.seats,
          macAddresses: tags,
          modulesPer: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Monthly") {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          version: currentCustomer.licenseInfo.versionId,
          startDateMon: moment(currentCustomer.licenseInfo.startDate, dateFormatList[0]),
          endDateMon: currentCustomer.licenseInfo.endDate,
          remainingDays: currentCustomer.licenseInfo.remainingDays,
          macAddresses: tags,
          modulesMon: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Trial") {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          version: currentCustomer.licenseInfo.versionId,
          startDateTrial: moment(currentCustomer.licenseInfo.startDate, dateFormatList[0]),
          endDateTrial: moment(currentCustomer.licenseInfo.endDate, dateFormatList[0]),
          macAddresses: tags,
          modulesTrial: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Student") {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          courseCode: currentCustomer.licenseInfo.courseCode,
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  } else if (currentCustomer.licenseInfo.licenseTypeModel.name === "Temporary") {
    editForm = [
      <Form
        key={1}
        name="basic"
        initialValues={{
          version: currentCustomer.licenseInfo.versionId,
          startDateTemp: moment(currentCustomer.licenseInfo.startDate, dateFormatList[0]),
          endDateTemp: moment(currentCustomer.licenseInfo.endDate, dateFormatList[0]),
          seats: currentCustomer.licenseInfo.seats,
          macAddresses: tags,
          modulesPer: currentCustomer.licenseInfo.moduleModels.map((module) => {
            return module.id;
          }),
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        {content}
      </Form>,
    ];
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #d3d3d3",
          borderRadius: "5px",
          padding: "1.5rem",
        }}
      >
        <Spin spinning={isButtonDisabled} tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}>
          <LeftCircleOutlined style={{ marginBottom: "1rem", fontSize: "2.5rem", color: "#008acd" }} onClick={props.handleShowEditForm} />
          {editForm}
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default UpdateCustomersForm;
