import React, { useEffect, useState } from "react";
import Customers from "./customers/Customers";
import Releases from "./releases/Releases";
import Log from "./log/Log";
import Users from "./users/Users";
import Roles from "./roles/Roles";
import SideNav from "../shared/side-nav/SideNav";
import Header from "../shared/header/Header";
import CustomerProfile from "./profile/CustomerProfile";
import CustomerLicenses from "./licenses/CustomerLicenses";
import RequestQuotation from "./quotation/RequestQuotation";
import { axiosIntance, protectedAxiosIntance } from "../API/Base";
import { useDispatch, useSelector } from "react-redux";
import { Logout, setProfile } from "../../actions/Profile";
import { setSidebarContent } from "../../actions/Sidebar";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";

const Container = (props) => {
  const [view, setView] = useState(6);
  const customer = useSelector((state) => state.profile.user.customer);
  const token = useSelector((state) => state.profile.token);
  const recent = useSelector((state) => state.sidebar.num);
  const dispatch = useDispatch();
  const history = useHistory();
  const [content, setContent] = useState(<Spin tip="Loading..." size="large" />);

  const tokenExpired = () => {
    message.warning("Session Expired");
    // axiosIntance
    //   .post(`/customers/logout`, null, { params: { token } })
    //   .then((res) => {
    //     if (res.data.statusCode === "OK") {
    //       console.log("logout Done");
    //     } else {
    //       console.log("logout error");
    //     }
    //   })
    //   .catch((err) => console.log(err));
    history.push("/");
    document.cookie = "token=;";
    localStorage.clear();
    dispatch(Logout());
    dispatch(setSidebarContent(6));
  };

  //reloading
  useEffect(() => {
    protectedAxiosIntance()
      .get("customers/validate-authentication")
      .then((res) => {
        if (res.data === "authentication is valid!" || res.status === 200) {
          if (window.performance) {
            if (String(window.performance.getEntriesByType("navigation")[0].type) === "reload") {
              protectedAxiosIntance()
                .post("customers/getCustomerData", {
                  id: customer.id,
                  email: customer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                  setView(recent);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        } else {
          tokenExpired();
        }
      })
      .catch((err) => (err.response.status === 410 ? tokenExpired() : console.log(err)));
  }, []);

  const handleClick = (num) => {
    if (num === 6 || num === 7) {
      protectedAxiosIntance()
        .post("customers/getCustomerData", {
          id: customer.id,
          email: customer.email,
        })
        .then((res) => {
          dispatch(setProfile(res.data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch(setSidebarContent(num));
    setView(num);
  };

  //changing view
  useEffect(() => {
    protectedAxiosIntance()
      .get("customers/validate-authentication")
      .then((res) => {
        console.log(res);
        if (res.data === "authentication is valid!" || res.status === 200) {
          if (view === 1) {
            setContent(<Customers isCustomer={props.isCustomer} />);
          } else if (view === 2) {
            setContent(<Releases />);
          } else if (view === 3) {
            setContent(<Log />);
          } else if (view === 4) {
            setContent(<Users />);
          } else if (view === 5) {
            setContent(<Roles />);
          } else if (view === 6) {
            setContent(<CustomerProfile isCustomer={props.isCustomer} />);
          } else if (view === 7) {
            setContent(<CustomerLicenses />);
          } else if (view === 8) {
            setContent(<RequestQuotation />);
          }
        } else {
          tokenExpired();
        }
      })
      .catch((err) => tokenExpired());
  }, [view]);

  return (
    <div style={{ display: "flex" }}>
      <SideNav handleClick={handleClick} view={view} isCustomer={props.isCustomer} />
      <div style={{ width: "100%", padding: "0 2rem" }}>
        <Header handleLogin={props.handleLogin} />
        <div style={{ height: "90vh", overflowY: "scroll" }}>{content}</div>
      </div>
    </div>
  );
};

export default Container;
