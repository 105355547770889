import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Popconfirm, Tooltip } from "antd";
import React from "react";
export const customersConstants = (deleteCustomer, handleViewCustomer, editRow, adminRole) => {
  const handleView = (customer) => {
    handleViewCustomer();
    editRow(customer.id);
  };
  return [
    {
      title: "Name",
      width: "30%",
      render: (rowData, index) => {
        return <p>{rowData.name}</p>;
      },
    },
    {
      title: "Email",
      width: "30%",
      render: (rowData, index) => {
        return <p>{rowData.email}</p>;
      },
    },
    {
      title: "Role",
      width: "30%",
      render: (rowData, index) => {
        return <p>{rowData.role}</p>;
      },
    },
    {
      title: "",
      width: "10%",
      render: (rowData, index) => {
        return (
          <div>
            <Tooltip title="view">
              <EyeOutlined style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => handleView(rowData)} />
            </Tooltip>
            {adminRole && (
              <Tooltip title="delete">
                <Popconfirm title="Are You Sure You Want to Delete?" onConfirm={() => deleteCustomer(rowData.id)}>
                  <CloseOutlined />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};
