import React from 'react';

export const codesConstants = () => {
  return [
    {
      title: 'Code',
      width:'48%',
      render: (rowData, index) => {
        return <p>{rowData.code}</p>
      },
    },
    {
        title: 'Start Date',
        width:'26%',
        render: (rowData, index) => {
          return <p>{rowData.startDate}</p>
        },
      },
    {
    title: 'End Date',
    width:'26%',
    render: (rowData, index) => {
        return <p>{rowData.expireDate}</p>
    },      
    },
  ]
}
