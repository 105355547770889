import React, { useState } from "react";
import { message } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import icon from "../../assets/SidlabIcon-02.png";
import icon1 from "../../assets/SplashScreen.png";
import "./Login.css";
import { axiosIntance } from "../API/Base";
import { useDispatch } from "react-redux";
import { setProfile } from "../../actions/Profile";
import "../required.css";
import SignUpForm from "../signUpForm/SignUpForm";
import LoginForm from "../LoginForm.js/LoginForm";
import ForgetPasswordEmail from "../forgetPassword/forgetPasswordEmail";

const Login = (props) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgetPassword, setisForgetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const customerString = "customer";
  const onFinish = (values) => {
    handleClick();
  };

  const info = () => {
    message.error("Wrong Email or Password!");
  };

  const handleClick = () => {
    setIsLoading(true);
    axiosIntance
      .post("customers/login", {
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.data.statusCode !== "INTERNAL_SERVER_ERROR") {
          document.cookie = `token=${res.data.data.access_token}; SameSite=Lax; Secure`;
          dispatch(setProfile(res.data.data));
          setIsLoading(false);
          const adminRole = res.data.data.customer.roles.find((role) => role.name === "Admin");
          const salesRole = res.data.data.customer.roles.find((role) => role.name === "Sales");
          const distributorRole = res.data.data.customer.roles.find((role) => role.name === "Distributor");
          const customerRole = res.data.data.customer.roles.find((role) => role.name === "Customer");

          if (adminRole) {
            props.setIsCustomer("");
            localStorage.setItem(customerString, "Admin");
          } else if (salesRole) {
            props.setIsCustomer("");
            localStorage.setItem(customerString, "Sales");
          } else if (distributorRole) {
            props.setIsCustomer("");
            localStorage.setItem(customerString, "Distributor");
          } else if (customerRole) {
            props.setIsCustomer("");
            localStorage.setItem(customerString, "Customer");
          }
        } else {
          if (res.data.message === "USER_DISABLED") {
            message.error("Please Activate Your Account Using The Link Sent To Your Email", 5);
            setIsLoading(false);
          } else {
            info();
            setIsLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSwitch = () => {
    setIsSignUp(!isSignUp);
  };

  const handleForgetPassword = () => {
    setisForgetPassword(!isForgetPassword);
  };
  let formComponent = null;
  if (isSignUp) {
    formComponent = <SignUpForm setIsSignUp={setIsSignUp} handleLogin={props.handleLogin} setIsCustomer={props.setIsCustomer} />;
  } else if (isForgetPassword) {
    formComponent = (
      <ForgetPasswordEmail
        setIsLoading={setIsLoading}
        setIsSignUp={setIsSignUp}
        isLoading={isLoading}
        handleForgetPassword={handleForgetPassword}
      />
    );
  } else {
    formComponent = (
      <LoginForm
        onFinish={onFinish}
        isLoading={isLoading}
        username={username}
        password={password}
        setUsername={setUsername}
        setPassword={setPassword}
        handleForgetPassword={handleForgetPassword}
      />
    );
  }
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div className="loginWrapper">
        <img style={{ marginBottom: "2rem", width: "5rem" }} src={icon} alt="sidlab" />
        <div className="loginBox">
          {formComponent}
          <div onClick={handleSwitch} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <i style={{ marginRight: "1rem" }}>
              <SyncOutlined />
            </i>
            <span>{isSignUp ? "Sign In" : "Sign Up"}</span>
          </div>
        </div>
      </div>
      <div className="second">
        <img style={{ margin: "auto", width: "50%" }} src={icon1} alt="sidlab" />
      </div>
    </div>
  );
};

export default Login;
