import React, { useState } from "react";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { axiosIntance } from "../API/Base";
import styles from "./SignUp.module.css";
import { countries } from "../countries";

const SignUpForm = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const { Option } = Select;

  const onFinish = (values) => {
    console.log("Success:", values);
    handleClick();
  };

  const info = () => {
    message.success("The verification has been sent to you, please check your inbox and click on Activate to activate your account");
  };
  const info1 = () => {
    message.info({
      content: (
        <div>
          This email has an account,
          <a style={{ color: "008acd" }} onClick={() => props.setIsSignUp(false)}>
            {" "}
            Sign in
          </a>
        </div>
      ),
    });
  };
  const info2 = () => {
    message.error("Something went wrong");
  };

  const handleClick = () => {
    //   history.push('/admin')
    //   props.handleLogin()
    //   props.setIsCustomer(true);
    setIsLoading(true);
    axiosIntance
      .post("customers/addCustomer", {
        firstName: firstName,
        lastName: lastName,
        password: password,
        countryId: countryId,
        organization: organization,
        email: email,
        phoneNumber: phone,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          props.setIsSignUp(false);
        } else if (res.data.message === "Email is exist") {
          info1();
        } else {
          info2();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        info2();
        setIsLoading(false);
      });
  };

  return (
    <div className="loginForm">
      <Form
        style={{ width: "100%" }}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input customer first name",
            },
            {
              pattern: "^[a-zA-Z]*$",
              message: "The input is not valid Name!",
            },
          ]}
        >
          <Input name="firstName" value={firstName} allowClear onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input customer last name",
            },
            {
              pattern: "^[a-zA-Z]*$",
              message: "The input is not valid Name!",
            },
          ]}
        >
          <Input name="lastName" value={lastName} allowClear onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
        </Form.Item>

        <Form.Item
          name="organization"
          rules={[
            {
              required: true,
              message: "Please input customer organization name",
            },
          ]}
        >
          <Input
            name="organization"
            allowClear
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            placeholder="Organization"
          />
        </Form.Item>

        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please input customer country",
            },
          ]}
        >
          <Select
            showSearch
            name="country"
            style={{ width: "100%" }}
            onChange={(value) => setCountryId(value)}
            optionFilterProp="children"
            placeholder="Country"
          >
            {countries.map((country) => {
              return (
                <Option value={country.id} key={country.id}>
                  {country.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input customer phone number",
            },
          ]}
        >
          <Input
            allowClear
            className={styles.inputNoCounter}
            type={"number"}
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: true,
              message: "Please input customer Email!",
            },
          ]}
        >
          <Input name="email" value={email} allowClear onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            () => ({
              validator(_, value) {
                if (!value || value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "password must contain at least one number, one uppercase and lowercase letter, symbol and 8 or more characters"
                  )
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password allowClear placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
        >
          <Input.Password allowClear placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="login-form-button">
            <Spin spinning={isLoading} style={{ marginRight: "1rem" }} /> Sign Up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignUpForm;
