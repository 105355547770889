import { Button, DatePicker, Form, Input, InputNumber, message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LeftCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { protectedAxiosIntance } from "../../API/Base";
import { setProfile } from "../../../actions/Profile";
import { useDispatch, useSelector } from "react-redux";

const monthly = 4;
const temporary = 6;
const permanent = 3;

const AddLicense = (props) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const loggedCustomer = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [licenseData, setLicenseData] = useState({
    category: "",
    licenseCpuType: "",
    licenseFnlType: "",
    version: props.latestVersion,
    purchaseDatePer: "",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    monthsPer: 12,
    modulesPer: [],
    courseCode: "",
    monthsMon: 1,
    modulesMon: [],
    seats: 1,
    startDate: "",
    endDate: "",
  });
  const disabledDate = (current) => {
    return licenseData.purchaseDatePer !== "" ? current && current < moment(licenseData.purchaseDatePer, "DD/MM/YYYY") : true;
  };
  const handleDateChange = (prop, endDate, date, dateString) => {
    prop.map((property) => {
      licenseData[property] = dateString;
    });
    licenseData.subscriptionEndDate = endDate;
    form.setFieldValue("subStartDatePer", date);
    form.setFieldValue("subscriptionEndDate", licenseData.subscriptionEndDate);
    setLicenseData({ ...licenseData, subscriptionEndDate: endDate });
  };
  const handleMonthlyDateChange = (prop, endDate, date, dateString) => {
    prop.map((property) => {
      licenseData[property] = dateString;
    });
    licenseData.endDate = endDate;
    form.setFieldValue("endDateMon", licenseData.endDate);
    setLicenseData({ ...licenseData, endDate: endDate });
  };
  const handleChange = (name, value) => {
    setLicenseData((prev) => ({ ...prev, [name]: value }));
  };
  const handleMonthNum = (name, value) => {
    let moment1 = moment(licenseData.subscriptionStartDate, "DD/MM/YYYY");
    let newDate = new Date(moment1._d);
    let numberOfMonthAdded = newDate.getDate() === 1 ? 0 : 1;
    newDate.setMonth(newDate.getMonth() + parseInt(value) + numberOfMonthAdded);
    newDate.setDate(1);
    licenseData.subscriptionEndDate = newDate.toLocaleDateString("en-GB");
    form.setFieldValue("subscriptionEndDate", licenseData.subscriptionEndDate);
    setLicenseData((prev) => ({ ...prev, [name]: value }));
  };
  const handleMonthlyChange = (name, value) => {
    let moment1 = moment(licenseData.startDate, "DD/MM/YYYY");
    let newDate = new Date(moment1._d);
    let numberOfMonthAdded = newDate.getDate() === 1 ? 0 : 1;
    newDate.setMonth(newDate.getMonth() + parseInt(value) + numberOfMonthAdded);
    newDate.setDate(1);
    licenseData.endDate = newDate.toLocaleDateString("en-GB");
    form.setFieldValue("endDateMon", licenseData.endDate);
    setLicenseData((prev) => ({ ...prev, [name]: value }));
  };
  const info = () => {
    message.success("The License Added successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };
  const onSubmit = () => {
    // need restructuring
    setButtonDisabled(true);
    if (licenseData.category === "FNL" && licenseData.licenseFnlType === permanent) {
      protectedAxiosIntance()
        .post(`licenses/addLicense`, {
          version: licenseData.version,
          seats: licenseData.seats,
          categoryName: licenseData.category,
          typeId: permanent,
          customerId: props.currentCustomer.id,
          purchaseDate: licenseData.purchaseDatePer,
          subscriptions: [
            {
              months: licenseData.monthsPer,
              subscriptionStartDate: licenseData.subscriptionStartDate,
              subscriptionEndDate: licenseData.subscriptionEndDate,
            },
          ],
          modules: licenseData.modulesPer,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (props.currentCustomer.id === loggedCustomer.profile.user.customer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: props.currentCustomer.id,
                  email: props.currentCustomer.email,
                })
                .then((res) => {
                  console.log(res.data.data);
                  dispatch(setProfile(res.data.data));
                });
            }
            props.handleAddLicenseForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else if (licenseData.category === "CPU" && licenseData.licenseCpuType === 5) {
      protectedAxiosIntance()
        .post(`licenses/addLicense`, {
          version: licenseData.version,
          categoryName: licenseData.category,
          typeId: 5,
          customerId: props.currentCustomer.id,
          courseCode: licenseData.courseCode,
          modules: [],
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (props.currentCustomer.id === loggedCustomer.profile.user.customer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: props.currentCustomer.id,
                  email: props.currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                });
            }
            props.handleAddLicenseForm();
          } else if (res.data.message === "Course Code Not Found") {
            message.error("The code you entered is not valid, please try another code");
          } else if (res.data.message === "Course already requested") {
            message.error("You have a student license generated with this Student code, please enter another code and try again");
          } else if (res.data.statusCode === "LOCKED") {
            message.error(res.data.message);
          } else if (res.data.message === "Maximum number exceeded") {
            message.error("The maximum students that can use this code is reached, please contact your teacher to get another code");
          } else if (res.data.message === "This is Your Course") {
            message.error("You can not request his own course");
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else if (licenseData.category === "CPU" && licenseData.licenseCpuType === monthly) {
      protectedAxiosIntance()
        .post(`licenses/addLicense`, {
          version: licenseData.version,
          categoryName: licenseData.category,
          typeId: monthly,
          customerId: props.currentCustomer.id,
          startDate: licenseData.startDate,
          endDate: licenseData.endDate,
          months: licenseData.monthsMon,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (props.currentCustomer.id === loggedCustomer.profile.user.customer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: props.currentCustomer.id,
                  email: props.currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                });
            }
            props.handleAddLicenseForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else if (licenseData.category === "CPU" && licenseData.licenseCpuType === permanent) {
      protectedAxiosIntance()
        .post(`licenses/addLicense`, {
          version: licenseData.version,
          categoryName: licenseData.category,
          typeId: permanent,
          customerId: props.currentCustomer.id,
          purchaseDate: licenseData.purchaseDatePer,
          subscriptions: [
            {
              months: licenseData.monthsPer,
              subscriptionStartDate: licenseData.subscriptionStartDate,
              subscriptionEndDate: licenseData.subscriptionEndDate,
            },
          ],
          modules: licenseData.modulesPer,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (props.currentCustomer.id === loggedCustomer.profile.user.customer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: props.currentCustomer.id,
                  email: props.currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                });
            }
            props.handleAddLicenseForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err.message);
          info1();
        });
    } else if (
      (licenseData.category === "FNL" && licenseData.licenseFnlType === temporary) ||
      (licenseData.category === "CPU" && licenseData.licenseCpuType === temporary)
    ) {
      protectedAxiosIntance()
        .post(`licenses/addLicense`, {
          version: licenseData.version,
          categoryName: licenseData.category,
          typeId: temporary,
          customerId: props.currentCustomer.id,
          startDate: licenseData.startDate,
          endDate: licenseData.endDate,
          modules: licenseData.modulesPer,
          seats: licenseData.seats,
        })
        .then((res) => {
          if (res.data.statusCode === "OK") {
            info();
            if (props.currentCustomer.id === loggedCustomer.profile.user.customer.id) {
              protectedAxiosIntance()
                .post(`customers/getCustomerData`, {
                  id: props.currentCustomer.id,
                  email: props.currentCustomer.email,
                })
                .then((res) => {
                  dispatch(setProfile(res.data.data));
                });
            }
            props.handleAddLicenseForm();
          } else {
            info1();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      message.error("Please Fill all the fields");
    }
  };

  useEffect(() => {
    return () => {
      props.resetSearch();
    };
  }, []);

  let items = [];
  if (licenseData.category === "CPU") {
    items = [
      <span key={1} style={{ fontWeight: "500" }}>
        License type
      </span>,
      <Form.Item
        key={2}
        // className='required'
        name="licenseType"
        rules={[
          {
            required: true,
            message: "Please input customer license type",
          },
        ]}
      >
        <Select
          allowClear
          name="licenseType"
          placeholder="License type"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("licenseCpuType", e);
          }}
        >
          {props.types.map((type, i) => {
            if (type.name === "FNL" || type.name === "Student" || type.name === "Trial") {
              return null;
            }
            return (
              <Option key={i} value={type.id}>
                {type.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
    ];
  } else if (licenseData.category === "FNL") {
    items = [
      <span key={1} style={{ fontWeight: "500" }}>
        License type
      </span>,
      <Form.Item
        key={2}
        // className='required'
        name="licenseType2"
        rules={[
          {
            required: true,
            message: "Please input customer license type",
          },
        ]}
      >
        <Select
          allowClear
          name="licenseType2"
          placeholder="License type"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("licenseFnlType", e);
          }}
        >
          {props.types.map((type) => {
            if (type.name === "Permanent" || type.name === "Temporary") {
              return <Option value={type.id}>{type.name}</Option>;
            }
          })}
        </Select>
      </Form.Item>,
    ];
  } else {
    items = [];
  }
  let seats = null;
  if (licenseData.category === "FNL") {
    seats = [
      <Form.Item
        key={1}
        name="seats"
        rules={[
          {
            required: true,
            message: "Please input your number of seats",
          },
        ]}
      >
        <label style={{ marginRight: "2rem" }}>Number of Seats:</label>
        <InputNumber
          min={1}
          name="seats"
          placeholder="Number of Seats"
          defaultValue={licenseData.seats}
          onChange={(e) => {
            handleMonthNum("seats", e);
          }}
        />
      </Form.Item>,
    ];
  } else {
    seats = [];
  }

  let licenseFileds = null;
  if (
    (licenseData.category === "FNL" && licenseData.licenseFnlType === permanent) ||
    (licenseData.category === "CPU" && licenseData.licenseCpuType === permanent)
  ) {
    licenseFileds = [
      <span key={1} style={{ fontWeight: "500" }}>
        Purchase Date
      </span>,
      <Form.Item
        key={2}
        valuePropName={"date"}
        name="purchaseDatePer"
        rules={[
          {
            required: true,
            message: "Please input purchase date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="purchaseDatePer"
          placeholder="Purchase date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              setLicenseData({ ...licenseData, purchaseDatePer: e.format("DD/MM/YYYY") });
            }
          }}
        />
      </Form.Item>,
      <span key={3} style={{ fontWeight: "500" }}>
        Subscription Start date
      </span>,

      <Form.Item
        key={4}
        valuePropName={"date"}
        name="subStartDatePer"
        validateStatus={`${
          moment(licenseData.subscriptionStartDate, "DD/MM/YYYY") >= moment(licenseData.purchaseDatePer, "DD/MM/YYYY") ||
          licenseData.purchaseDatePer === "" ||
          licenseData.purchaseDatePer === null ||
          licenseData.subscriptionStartDate === "" ||
          licenseData.subscriptionStartDate === null
            ? ""
            : "error"
        }`}
        help={
          moment(licenseData.subscriptionStartDate, "DD/MM/YYYY") < moment(licenseData.purchaseDatePer, "DD/MM/YYYY")
            ? "Subscription start date should be after purchase date"
            : ""
        }
        rules={[
          {
            required: true,
            message: "Please input subscription start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="subStartDatePer"
          placeholder="Subscription Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              let newDate = new Date(e._d);
              let numberOfMonthAdded = e.date() === 1 ? 0 : 1;
              newDate.setMonth(e.month() + parseInt(licenseData.monthsPer) + numberOfMonthAdded);
              newDate.setDate(1);
              handleDateChange(["subscriptionStartDate"], newDate.toLocaleDateString("en-GB"), e, e.format("DD/MM/YYYY"));
            }
          }}
          disabledDate={disabledDate}
        />
      </Form.Item>,
      <span key={5} style={{ fontWeight: "500" }}>
        Subscription End date
      </span>,

      <Form.Item key={6} name="subscriptionEndDate">
        <Input
          disabled
          name="subscriptionEndDate"
          placeholder="Subscription End Date"
          style={{ width: "100%" }}
          type="text"
          value={licenseData.subscriptionEndDate}
        />
      </Form.Item>,

      <Form.Item
        key={7}
        name="monthsPer"
        rules={[
          {
            required: true,
            message: "Please input your number of months",
          },
        ]}
      >
        <label style={{ marginRight: "2rem" }}>Number of Months:</label>
        <InputNumber
          min={1}
          name="monthsPer"
          placeholder="Number of Months"
          defaultValue={licenseData.monthsPer}
          onChange={(e) => {
            handleMonthNum("monthsPer", e);
          }}
        />
      </Form.Item>,

      [seats],
      <span key={8} style={{ fontWeight: "500" }}>
        Modules
      </span>,

      <Form.Item
        key={9}
        name="modulesPer"
        // className='required'
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesPer"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesPer", e);
          }}
          allowClear
        >
          {props.modules.map((module) => {
            return (
              <Option key={module.id} value={module.id}>
                {module.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>,
    ];
  } else if (licenseData.category === "CPU" && licenseData.licenseCpuType === 5) {
    licenseFileds = [
      <span style={{ fontWeight: "500" }}>Course Code</span>,

      <Form.Item
        // className='required'
        name="courseCode"
        rules={[
          {
            required: true,
            message: "Please input course code",
          },
        ]}
      >
        <Input
          name="courseCode"
          placeholder="Course Code"
          allowClear
          //defaultValue={12}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
      </Form.Item>,
    ];
  } else if (licenseData.category === "CPU" && licenseData.licenseCpuType === monthly) {
    licenseFileds = [
      <span style={{ fontWeight: "500" }}>Start date</span>,
      <Form.Item
        // className='required'
        valuePropName={"date"}
        name="startDateMon"
        rules={[
          {
            required: true,
            message: "Please input Start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="startDateMon"
          placeholder="Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              let newDate = new Date(e._d);
              newDate.setMonth(e.month() + parseInt(licenseData.monthsMon));
              handleMonthlyDateChange(["startDate"], newDate.toLocaleDateString("en-GB"), e, e.format("DD/MM/YYYY"));
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>End date</span>,
      <Form.Item
        // className='required'
        name="endDateMon"
      >
        <Input
          disabled
          name="endDateMon"
          placeholder="End Date"
          style={{ width: "100%" }}
          // ref={ref}
          type="text"
          value={licenseData.endDate}
        />
      </Form.Item>,
      <Form.Item
        // className='required'
        name="monthsMon"
        rules={[
          {
            required: true,
            message: "Please input your number of months",
          },
        ]}
      >
        <label style={{ marginRight: "2rem" }}>Number of Months:</label>
        <InputNumber
          min={1}
          name="monthsMon"
          placeholder="Number of Months"
          defaultValue={licenseData.monthsMon}
          onChange={(e) => {
            handleMonthlyChange("monthsMon", e);
          }}
        />
      </Form.Item>,
    ];
  } else if (
    (licenseData.category === "CPU" && licenseData.licenseCpuType === temporary) ||
    (licenseData.category === "FNL" && licenseData.licenseFnlType === temporary)
  ) {
    licenseFileds = [
      <span style={{ fontWeight: "500" }}>Start date</span>,
      <Form.Item
        // className='required'
        valuePropName={"date"}
        name="startDateMon"
        rules={[
          {
            required: true,
            message: "Please input Start date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="startDateMon"
          placeholder="Start date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              setLicenseData({ ...licenseData, startDate: e.format("DD/MM/YYYY") });
            }
          }}
        />
      </Form.Item>,
      <span style={{ fontWeight: "500" }}>End date</span>,

      <Form.Item
        name="endDateMon"
        valuePropName={"date"}
        rules={[
          {
            required: true,
            message: "Please input End date",
          },
        ]}
      >
        <DatePicker
          allowClear
          name="endDateMon"
          placeholder="End Date"
          format={dateFormatList}
          style={{ width: "100%" }}
          onChange={(e) => {
            if (e) {
              setLicenseData({ ...licenseData, endDate: e.format("DD/MM/YYYY") });
            }
          }}
        />
      </Form.Item>,
      [seats],
      <span style={{ fontWeight: "500" }}>Modules</span>,

      <Form.Item
        name="modulesPer"
        // className='required'
        rules={[
          {
            required: true,
            message: "Please input customer Purchased Modules",
          },
        ]}
      >
        <Select
          mode="multiple"
          name="modulesPer"
          placeholder="Purchased modules"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("modulesPer", e);
          }}
          allowClear
        >
          {props.modules.map((module) => {
            return <Option value={module.id}>{module.name}</Option>;
          })}
        </Select>
      </Form.Item>,
    ];
  } else {
    licenseFileds = [];
  }
  let content = null;
  content = [
    <Form
      key={1}
      name="basic"
      form={form}
      initialValues={{
        version: props.latestVersion,
        monthsPer: licenseData.monthsPer,
        monthsMon: licenseData.monthsMon,
        seats: licenseData.seats,
        // licenseType2: "",
      }}
      onFinish={onSubmit}
      onFinishFailed={() => message.error("Please Fill All Fields Then Try Again")}
    >
      <span style={{ fontWeight: "500" }}>License Category</span>

      <Form.Item
        name="licenseCategory"
        rules={[
          {
            required: true,
            message: "Please input customer license category",
          },
        ]}
      >
        <Select
          allowClear
          name="licenseCategory"
          placeholder="License Category"
          style={{ width: "100%" }}
          onChange={(e) => {
            handleChange("category", e);
          }}
        >
          {props.category.map((cat) => {
            return (
              <Option value={cat.name} key={cat.id}>
                {cat.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      {items}
      <span style={{ fontWeight: "500" }}>Version</span>

      <Form.Item
        // className='required'
        name="version"
      >
        <Select
          defaultValue={props.latestVersion}
          value={props.latestVersion}
          name="version"
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={(e) => {
            console.log(e);
            handleChange("version", e);
          }}
          placeholder="version"
        >
          {props.versions.map((version) => {
            return (
              <Option value={version.id} key={version.id}>
                {version.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      {licenseFileds}
      <div>
        <Form.Item shouldUpdate style={{ marginRight: "1rem", display: "inline-block", paddingLeft: "10px" }}>
          <Button
            disabled={
              isButtonDisabled ||
              moment(licenseData.subscriptionStartDate, "DD/MM/YYYY") < moment(licenseData.purchaseDatePer, "DD/MM/YYYY")
            }
            style={{ backgroundColor: "#008acd", borderRadius: "7px", color: "#ffffff" }}
            // type="primary"
            htmlType="submit"
          >
            {isButtonDisabled ? "Adding..." : "Add License"}
          </Button>
        </Form.Item>

        <Button
          style={{
            display: "inline-block",
            backgroundColor: "#008acd",
            borderRadius: "7px",
            color: "#ffffff",
          }}
          onClick={props.handleAddLicenseForm}
        >
          Cancel
        </Button>
      </div>
    </Form>,
  ];
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #d3d3d3",
          borderRadius: "5px",
          padding: "1.5rem",
        }}
      >
        <Spin spinning={isButtonDisabled} tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}>
          <LeftCircleOutlined style={{ marginBottom: "1rem", fontSize: "2.5rem", color: "#008acd" }} onClick={props.handleAddLicenseForm} />
          {content}
        </Spin>
      </div>
    </React.Fragment>
  );
};
export default AddLicense;
