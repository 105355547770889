import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Container from "./components/container/Container";
import Activated from "./components/activated/Activated";
import Login from "./components/login/Login";
import "./App.css";
import { useSelector } from "react-redux";
import ForgetPassword from "./components/forgetPassword/forgetPassword";

const App = () => {
  const isAuth = useSelector((state) => (state.profile.token ? true : false));

  const [isCustomer, setIsCustomer] = useState("");

  useEffect(() => {
    setIsCustomer(localStorage.getItem("customer"));
  }, [isCustomer]);

  if (!isAuth) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/activated" render={() => <Activated />} />
          <Route path="/update-password-page" render={() => <ForgetPassword />} />
          <Route path="/" render={() => <Login setIsCustomer={setIsCustomer} />} />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" render={() => <Container isCustomer={isCustomer} />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
