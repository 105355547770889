import React, { useEffect, useRef, useState } from "react";
import { axiosDownload, protectedAxiosIntance, axiosIntance } from "../../API/Base";
import { Button, Col, Form, message, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../actions/Profile";
import prompt from "antd-prompt";
import CustomerCourseCodes from "../customers/customer-codes/CustomerCourseCodes";
import styles from "./CustomerLicenses.module.css";
import MacInput from "../components/MacInput";

const CustomerLicenses = (props) => {
  const customer = useSelector((state) => state.profile.user.customer);
  const licenses = useSelector((state) => state.profile);
  const [genOffline, setGenOffline] = useState(false);
  const [trialSuccess, setTrialSuccess] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState({
    sidlab: { link: "http://downloads.sidlab.se/sidlab5/SIDLAB511.exe", version: "5.1.1" },
    fnl: { link: "http://downloads.sidlab.se/fnlm5/SIDLAB511_FNLManager.exe", version: "5.1.1" },
    matlab: {
      link: "https://ssd.mathworks.com/supportfiles/downloads/R2023a/Release/5/deployment_files/installer/complete/win64/MATLAB_Runtime_R2023a_Update_5_win64.zip",
      version: "R2023a",
    },
  });
  const [offlineLicenseData, setOfflineLicenseData] = useState({
    id: "",
    mac1: "",
    mac2: "",
    mac3: "",
    mac4: "",
    mac5: "",
    mac6: "",
  });
  const mac1Ref = useRef(null);
  const mac2Ref = useRef(null);
  const mac3Ref = useRef(null);
  const mac4Ref = useRef(null);
  const mac5Ref = useRef(null);
  const mac6Ref = useRef(null);
  const dispatch = useDispatch();
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    if (customer.id) {
      protectedAxiosIntance()
        .get(`courses/findCourse/${customer.id}`)
        .then((res) => {
          setCodes(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [customer]);
  const success = () => {
    message.success("Your trial license is created successfully.");
    setTrialSuccess(true);
    // window.location.reload(false);
  };

  const error = () => {
    message.error("This account has already a trial license, to generate another one please contact info@sidlab.se!", 5);
  };

  const msgStudentLicenseRequested = () => {
    message.success("Student License Requested");
  };

  const msgWrongCourseCode = () => {
    message.error("No Course Codes Currently Available Please Contact info@sidlab.se", 5);
  };

  const adminRole = customer.roles.find((role) => role.name === "Admin");

  const allowDisplayOfflineLicense = customer.offlineLicenseAllowed ? "" : "none";

  const isValidMACAddress = (str) => {
    let regex = new RegExp(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/);

    // if (str == null) {
    //     return false;
    // }

    if (regex.test(str) === true) {
      return true;
    } else {
      return false;
    }
  };

  const isValidHexa = (str) => {
    let regex = new RegExp("^[A-Fa-f0-9]{2}$");
    if (str == null) {
      return true;
    }
    if (regex.test(str) === true) {
      return true;
    } else {
      return false;
    }
  };

  const download = (text, license) => {
    const element = document.createElement("a");
    const fileName = license ? `${license.licenseCategoryModel.name} - ${license.licenseTypeModel.name}.txt` : "SIDLAB-offline-license.txt";
    element.style.display = "none";
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const onOk = () => {
    if (
      isValidHexa(offlineLicenseData.mac1) &&
      isValidHexa(offlineLicenseData.mac2) &&
      isValidHexa(offlineLicenseData.mac3) &&
      isValidHexa(offlineLicenseData.mac4) &&
      isValidHexa(offlineLicenseData.mac5) &&
      isValidHexa(offlineLicenseData.mac6)
    ) {
      setGenOffline(false);
      const macAddress =
        `${offlineLicenseData.mac1}:${offlineLicenseData.mac2}:${offlineLicenseData.mac3}:${offlineLicenseData.mac4}:${offlineLicenseData.mac5}:${offlineLicenseData.mac6}`.toUpperCase();

      axiosDownload()
        .get(`/licenses/generateOfflineLicense?licenseId=${offlineLicenseData.id}&macAddress=${macAddress}`)
        .then((res) => {
          protectedAxiosIntance()
            .post(`customers/getCustomerData`, {
              id: customer.id,
              email: customer.email,
            })
            .then((res) => {
              dispatch(setProfile(res.data.data));
            })
            .catch((err) => {
              console.log(err);
            });
          download(res.data);
        });
    } else {
      message.error("Please Enter A Valid MAC Address");
    }
  };

  const handleRequest = () => {
    protectedAxiosIntance()
      .post("licenses/addTrialLicense", {
        customerId: customer.id,
        typeId: 1,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          axiosIntance.get("versions/getVersions").then((res) => {
            if (res.data.statusCode === "OK") {
              const latestVersionsData = res.data.data.slice(-1)[0];
              if (latestVersionsData.sidlabDownloadLink) {
                setDownloadLinks((prev) => ({
                  ...prev,
                  sidlab: { link: latestVersionsData.sidlabDownloadLink, version: latestVersionsData.name },
                  matlab: { ...prev.matlab, link: latestVersionsData.matlabRuntimeDownloadLink },
                  fnl: { link: latestVersionsData.fnlDownloadLink, version: latestVersionsData.name },
                }));
              }
            }
          });
          success();
          protectedAxiosIntance()
            .post(`customers/getCustomerData`, {
              id: customer.id,
              email: customer.email,
            })
            .then((res) => {
              dispatch(setProfile(res.data.data));
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (res.data.message === "trial license was requested before") {
          error();
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinish = (value) => {
    protectedAxiosIntance()
      .post(`courses/addStudentLicense`, {
        courseCode: value,
        customerId: customer.id,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          protectedAxiosIntance()
            .post(`customers/getCustomerData`, {
              id: customer.id,
              email: customer.email,
            })
            .then((res) => {
              dispatch(setProfile(res.data.data));
            })
            .catch((err) => {
              console.log(err);
            });
          msgStudentLicenseRequested();
        } else if (res.data.message === "Course Code Not Found") {
          message.error("The code you entered is not valid, please try another code");
        } else if (res.data.message === "Course already requested") {
          message.error("You have a student license generated with this Student code, please enter another code and try again");
        } else if (res.data.statusCode === "LOCKED") {
          message.error(res.data.message);
        } else if (res.data.message === "Maximum number exceeded") {
          message.error("The maximum students that can use this code is reached, please contact your teacher to get another code");
        } else if (res.data.message === "This is Your Course") {
          message.error("You can not request your course");
        } else {
          msgWrongCourseCode();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onRequestStudentLicense = async () => {
    try {
      await prompt({
        title: "Please Enter Course Code",
        placeholder: "Course Code",
        rules: [
          {
            required: true,
            message: "You must enter course code",
          },
        ],
        onOk: onFinish,
        autoFocus: true,
        modalProps: {
          okButtonProps: {
            style: { borderRadius: "7px", backgroundColor: "#008acd" },
          },
          okText: "Submit",
          cancelButtonProps: {
            style: {
              borderRadius: "7px",
              backgroundColor: "#008acd",
              color: "#fff",
            },
          },
        },
      });
      //msg depending on the api response
      // invalid or expired course code
      // studend license added successfully --> page rerenders
    } catch (e) {
      // msgWrongCourseCode()
    }
  };

  const isTodayDateBigger = (endDate) => {
    let endDateArray = endDate.split("/");
    let todaysDateArray = new Date()
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/");
    let endDateObj = new Date(endDateArray[2], endDateArray[1], endDateArray[0]);
    let todaysDateObj = new Date(todaysDateArray[2], todaysDateArray[1], todaysDateArray[0]);
    if (todaysDateObj > endDateObj) {
      return true;
    } else {
      return false;
    }
  };

  const onClickGenerateOffline = (license) => {
    if (license.macAddresses?.length > 0) {
      axiosDownload()
        .get(`/licenses/generateOfflineLicense?licenseId=${license.id}&macAddress=ay7aga`)
        .then((res) => download(res.data, license));
    } else {
      setOfflineLicenseData({ id: license?.id });
      setGenOffline(true);
    }
  };

  const handleJumpToNext = (e, next, current, previous) => {
    if (e.key === "Delete" || e.key === "Backspace" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
      if (e.key === "Backspace" && e.target.value.length === 0) {
        previous.current.focus();
      }
    } else if (isValidHexa(e.target.value) && e.target.value.length === e.target.maxLength && e.key !== "Tab") {
      next.current.focus();
      next.current.setSelectionRange(0, 0); //not working
      current.current.blur(); //not working
    }
  };

  const handlePaste = async (e) => {
    //needs <= 6 then map add values cause this gives error even if i added 1 value only
    const pastedText = await e?.clipboardData?.getData("text/plain");
    let splitValues;
    if (pastedText?.includes("-")) {
      splitValues = pastedText?.trim().split("-");
      if (splitValues?.length !== 6) {
        message.error("Please Paste a valid Mac Address");
        console.log(splitValues);
      } else {
        setOfflineLicenseData({
          ...offlineLicenseData,
          mac1: splitValues[0],
          mac2: splitValues[1],
          mac3: splitValues[2],
          mac4: splitValues[3],
          mac5: splitValues[4],
          mac6: splitValues[5],
        });
      }
    } else if (pastedText?.includes(":")) {
      splitValues = pastedText?.trim().split(":");
      if (splitValues?.length !== 6) {
        message.error("Please Paste a valid Mac Address");
      } else {
        setOfflineLicenseData({
          ...offlineLicenseData,
          mac1: splitValues[0],
          mac2: splitValues[1],
          mac3: splitValues[2],
          mac4: splitValues[3],
          mac5: splitValues[4],
          mac6: splitValues[5],
        });
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginTop: "2rem", marginRight: "2rem", width: "100%" }}>
        <Row className="tViewRowHeader">License Information</Row>

        {licenses.user.licenses.map(
          (license, key) =>
            license?.licenseTypeModel.name === "Permanent" ? (
              <Row className="tViewRow" key={key}>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Category
                </Col>
                <Col span={12} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseCategoryModel.name}
                </Col>
                <Col span={3} style={{ display: `${allowDisplayOfflineLicense}` }}>
                  <button
                    onClick={() => {
                      onClickGenerateOffline(license);
                    }}
                    className={styles.genBtn}
                    type="primary"
                  >
                    <span className={styles.genBtnText}>
                      {license.macAddresses?.length > 0 ? "Download Offline License" : "Generate Offline License"}
                    </span>
                  </button>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Type
                </Col>
                <Col span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseTypeModel.name}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Purchase date
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.purchaseDate}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Renewal date
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.renewDate}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Subscription Start date
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.licenseSubscriptionModels[0].subscriptionStartDate}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Subscription End date
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.licenseSubscriptionModels[0].subscriptionEndDate}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Number of Months
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.licenseSubscriptionModels[0].months}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Eligible version
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.version}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Modules
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.moduleModels.map((module) => module.name).join(", ")}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  MAC addresses
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.macAddresses
                    ? license?.macAddresses.length === 1
                      ? license?.macAddresses[0]
                      : license?.macAddresses.join(", ")
                    : ""}
                </Col>
                {license?.licenseCategoryModel.name === "FNL" && [
                  <Col key={1} span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                    Number of seats
                  </Col>,
                  <Col key={2} span={15} className="tViewRowCell">
                    {license?.seats}
                  </Col>,
                ]}
              </Row>
            ) : license?.licenseTypeModel.name === "Trial" ? (
              <Row className="tViewRow" key={key}>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Category
                </Col>
                <Col span={12} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseCategoryModel?.name}
                </Col>
                <Col span={3} style={{ display: `${allowDisplayOfflineLicense}` }}>
                  <button
                    onClick={() => {
                      onClickGenerateOffline(license);
                    }}
                    className={`${styles.genBtn} ${isTodayDateBigger(license.endDate) ? styles.displayNone : ""}`}
                    type="primary"
                  >
                    <span className={styles.genBtnText}>
                      {license.macAddresses?.length > 0 ? "Download Offline License" : "Generate Offline License"}
                    </span>
                  </button>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Type
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.licenseTypeModel.name}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Start date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.startDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  End date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.endDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Eligible version
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.version}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Modules
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.moduleModels.map((module) => module.name).join(", ")}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  MAC address
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.macAddresses
                    ? license?.macAddresses.length === 1
                      ? license?.macAddresses[0]
                      : license?.macAddresses.join(", ")
                    : ""}
                </Col>
              </Row>
            ) : license?.licenseTypeModel.name === "Student" ? (
              <Row className="tViewRow" key={key}>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Category
                </Col>
                <Col span={12} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseCategoryModel.name}
                </Col>
                <Col span={3} style={{ display: `${allowDisplayOfflineLicense}` }}>
                  <button
                    onClick={() => {
                      onClickGenerateOffline(license);
                    }}
                    className={`${styles.genBtn} ${isTodayDateBigger(license.endDate) ? styles.displayNone : ""}`}
                    type="primary"
                  >
                    <span className={styles.genBtnText}>
                      {license.macAddresses?.length > 0 ? "Download Offline License" : "Generate Offline License"}
                    </span>
                  </button>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Type
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.licenseTypeModel.name}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Start date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.startDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  End date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.endDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Eligible version
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.version}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Modules
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.moduleModels.map((module) => module.name).join(", ")}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  MAC address
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.macAddresses
                    ? license?.macAddresses.length === 1
                      ? license?.macAddresses[0]
                      : license?.macAddresses.join(", ")
                    : ""}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Course code
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.courseCode}
                </Col>
              </Row>
            ) : license?.licenseTypeModel.name === "Monthly" ? (
              <Row className="tViewRow" key={key}>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Category
                </Col>
                <Col span={12} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseCategoryModel.name}
                </Col>
                <Col span={3} style={{ display: `${allowDisplayOfflineLicense}` }}>
                  <button
                    onClick={() => {
                      onClickGenerateOffline(license);
                    }}
                    className={`${styles.genBtn} ${isTodayDateBigger(license.endDate) ? styles.displayNone : ""}`}
                    type="primary"
                  >
                    <span className={styles.genBtnText}>
                      {license.macAddresses?.length > 0 ? "Download Offline License" : "Generate Offline License"}
                    </span>
                  </button>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Type
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.licenseTypeModel.name}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Start date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.startDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  End date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.endDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Eligible version
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.version}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Modules
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.moduleModels.map((module) => module.name).join(", ")}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  MAC address
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.macAddresses
                    ? license?.macAddresses.length === 1
                      ? license?.macAddresses[0]
                      : license?.macAddresses.join(", ")
                    : ""}
                </Col>

                {/* <Col span={9} className='tViewRowCell'  style={{fontWeight: '500'}}>Number of months</Col>,
                <Col span={14} className='tViewRowCell'>{license}</Col>, */}
              </Row>
            ) : license?.licenseTypeModel.name === "Temporary" ? (
              <Row className="tViewRow" key={key}>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Category
                </Col>
                <Col span={12} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseCategoryModel.name}
                </Col>
                <Col span={3} style={{ display: `${allowDisplayOfflineLicense}` }}>
                  <button
                    onClick={() => {
                      onClickGenerateOffline(license);
                    }}
                    className={`${styles.genBtn} ${isTodayDateBigger(license.endDate) ? styles.displayNone : ""}`}
                    type="primary"
                  >
                    <span className={styles.genBtnText}>
                      {license.macAddresses?.length > 0 ? "Download Offline License" : "Generate Offline License"}
                    </span>
                  </button>
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  License Type
                </Col>
                <Col span={15} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  {license?.licenseTypeModel.name}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Eligible version
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.version}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Start date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.startDate}
                </Col>

                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  End date
                </Col>
                <Col span={14} className="tViewRowCell">
                  {license?.endDate}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  Modules
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.moduleModels.map((module) => module.name).join(", ")}
                </Col>
                <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                  MAC addresses
                </Col>
                <Col span={15} className="tViewRowCell">
                  {license?.macAddresses
                    ? license?.macAddresses.length === 1
                      ? license?.macAddresses[0]
                      : license?.macAddresses.join(", ")
                    : ""}
                </Col>
                {license?.licenseCategoryModel.name === "FNL" && [
                  <Col span={9} className="tViewRowCell" style={{ fontWeight: "500" }}>
                    Number of seats
                  </Col>,
                  <Col span={15} className="tViewRowCell">
                    {license?.seats}
                  </Col>,
                ]}
              </Row>
            ) : (
              ""
            )

          //'Monthly lease' & 'CPU'
        )}

        <Modal
          destroyOnClose={true}
          title="Generate Offline License"
          visible={genOffline}
          onOk={onOk}
          onCancel={() => setGenOffline(false)}
        >
          <MacInput
            label={"MAC Address :"}
            handlePaste={handlePaste}
            offlineLicenseData={offlineLicenseData}
            setOfflineLicenseData={setOfflineLicenseData}
            handleJumpToNext={handleJumpToNext}
            isValidHexa={isValidHexa}
            mac1Ref={mac1Ref}
            mac2Ref={mac2Ref}
            mac3Ref={mac3Ref}
            mac4Ref={mac4Ref}
            mac5Ref={mac5Ref}
            mac6Ref={mac6Ref}
          />
        </Modal>
        <Modal
          title="SIDLAB Trial"
          visible={trialSuccess}
          okButtonProps={{ style: { borderRadius: "6px", backgroundColor: "#008acd" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setTrialSuccess(false)}
          onOk={() => setTrialSuccess(false)}
          centered={true}
          width={800}
        >
          <div className={styles.trialModalNextSteps}>Next Steps:</div>
          <ul className={styles.trialModalUL}>
            <li>
              Download SIDLAB {downloadLinks.sidlab.version} from the upper download tab on your license manager page or through this{" "}
              <a href={downloadLinks.sidlab.link}>link</a>.
            </li>
            <li>
              Download the MATLAB Runtime Engine from the upper download tap on your license manager page or through this{" "}
              <a href={downloadLinks.matlab.link}>link</a>.
            </li>
            <li>Install MATLAB Runtime Engine then {downloadLinks.sidlab.link.split("/").pop()}</li>
            <li>Open SIDLAB shortcut and sign in with your credential (Email & Password), then activate your license.</li>
            <li>Enjoy your trial version.</li>
          </ul>
        </Modal>
        <Row>
          <Form>
            {/* <Input style={{margin: '5px'}} value={courseCodeInput} onChange={(e)=>setCourseCodeInput(e.target.value)} placeholder='Please Input Your Course Code' /> */}
            <Button
              style={{
                borderRadius: "7px",
                backgroundColor: "#008acd",
                color: "#fff",
                marginRight: "1rem",
              }}
              onClick={handleRequest}
            >
              Request Trial License
            </Button>
            <Button
              onClick={() => onRequestStudentLicense()}
              style={{
                borderRadius: "7px",
                backgroundColor: "#008acd",
                color: "#fff",
                marginRight: "1rem",
                marginBottom: "1rem",
              }}
            >
              Request Student License
            </Button>
          </Form>
        </Row>
      </div>
      {customer.teacher ? (
        <div
          className="scrolling"
          style={{
            width: "55%",
            paddingTop: "2rem",
            marginRight: "1rem",
            // display:'inline-block'
            // height:'50rem', overflowY:'scroll'
          }}
        >
          <CustomerCourseCodes adminRole={adminRole} customer={customer} codes={codes} setCodes={setCodes} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerLicenses;
