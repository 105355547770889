import React, { useState } from 'react';
import {Form, Button, Input, message, Spin} from 'antd';
import { useHistory } from "react-router-dom";
import icon1 from '../../assets/SplashScreen.png';
import './Login.css';

import {  Base, protectedAxiosIntance } from '../API/Base';
import { useDispatch } from 'react-redux';

const ForgetPassword = (props) => {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');

    const onFinish = values => {
        console.log('Success:', values);
        handleForgetPassword();

      };

    //   const apiKey = localStorage.getItem('token');

    //   useEffect(() => {
    //       if (apiKey) {
           
    //       }
          
    //   }, [apiKey]);
    const info = () => {
        if(password===confirmPassword){
            console.log('true')
        }else{
            message.error('Password and Confirm Password Do Not Match!');
        }
       
    };
  
    const URL= window.location.href
    let paramString = URL.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    const code = queryString.get('urlToken')
  
      const handleForgetPassword = () => {
        setIsLoading(true);
        Base.post('customers/update-password', {
        newPassword: password,
        urlToken:code
        }).then((res) => {
            if (res.data.statusCode !== 'INTERNAL_SERVER_ERROR') {
             history.push('/login')
            } else {
            info();
            setIsLoading(false);
          }
        }).catch(err => console.log(err))
    }

    return (
        <div style={{width:'100%', display:'flex'}}>
        <div className='loginWrapper'>
            {/* <img style={{marginBottom:'2rem', width:'5rem'}} src={icon} alt='sidlab'/> */}
            <div className='loginBox'>
               <div className='loginForm'>
               <Form
               style={{width:'100%'}}
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name=" New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            () => ({
                                validator(_, value) {
                                if (!value || value.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('password must contain at least one number, one uppercase and lowercase letter, symbol and 8 or more characters'));
                                },
                            }),
                            ]}
                            hasFeedback
                    >
                        <Input.Password
                        allowClear
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item
                        name="Confirm Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            () => ({
                                validator(_, value) {
                                if (!value || value.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('password must contain at least one number, one uppercase and lowercase letter, symbol and 8 or more characters'));
                                },
                            }),
                            ]}
                            hasFeedback
                    >
                        <Input.Password
                        allowClear
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        />
                    </Form.Item>
                        <Form.Item>
                            <Button 
                                style={{width:'100%',backgroundColor:"#008acd"}} 
                               type="primary" htmlType="submit" className="login-form-button">
                                <Spin spinning={isLoading} style={{marginRight: '1rem'}} /> Change Password
                            </Button> 
                        </Form.Item>                      

                </Form>
               </div>
             
            </div>
        </div>
        <div className='second'>
            <img style={{margin:'auto', width:'50%'}} src={icon1} alt='sidlab'/>

            </div>
        </div>
    )
}

export default ForgetPassword;
