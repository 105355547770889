import React, { useState } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import moment from "moment";

const UpdateReleaseForm = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const [currentRelease, setCurrentRelease] = useState(props.currentRelease);

  const handleChange = (name, value) => {
    console.log(name);
    console.log(value);
    setCurrentRelease((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date, dateString) => {
    setCurrentRelease({ ...currentRelease, date: dateString });
  };

  const onFinish = () => {
    props.updateRelease(currentRelease.id, currentRelease);
    form.resetFields();
    props.handleShowEditForm();
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #d3d3d3",
          borderRadius: "5px",
          padding: "1.5rem",
        }}
      >
        <Form
          name="basic"
          initialValues={{
            name: currentRelease.name,
            notes: currentRelease.notes,
            date: moment(currentRelease.date, dateFormatList[0]),
            sidlabDownloadLink: currentRelease.sidlabDownloadLink,
            fnlDownloadLink: currentRelease.fnlDownloadLink,
            matlabRuntimeDownloadLink: currentRelease.matlabRuntimeDownloadLink,
          }}
          form={form}
          onFinish={onFinish}
        >
          <span style={{ fontWeight: "500" }}>Version</span>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Release Version",
              },
              () => ({
                validator(_, value) {
                  let pattern = new RegExp(/^\d+(\.\d+){1,3}$/);
                  if (pattern.test(value) || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Release Version must contain only numbers and dots"));
                },
              }),
            ]}
          >
            <Input name="name" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Release date</span>
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Please input Release Date",
              },
            ]}
          >
            <DatePicker name="date" format={dateFormatList} placeholder="" style={{ width: "100%" }} onChange={handleDateChange} />
          </Form.Item>
          <span style={{ fontWeight: "500" }}>SIDLAB Download Link</span>
          <Form.Item name="sidlabDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="sidlabDownloadLink" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>FNL Download Link</span>
          <Form.Item name="fnlDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="fnlDownloadLink" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>MATLAB Download Link</span>
          <Form.Item name="matlabRuntimeDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="matlabRuntimeDownloadLink" onChange={(e) => handleChange(e.target.name, e.target.value)} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Notes</span>
          <Form.Item name="notes">
            <TextArea
              name="notes"
              style={{ marginBottom: "2.4rem" }}
              rows={3}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            >
              {currentRelease.notes}
            </TextArea>
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Button
              style={{
                paddingRight: "3rem",
                paddingLeft: "3rem",
                borderRadius: "5px",
                marginRight: "1rem",
                backgroundColor: "#008acd",
              }}
              type="primary"
              htmlType="submit"
            >
              Update release
            </Button>
            <Button style={{ borderRadius: "5px" }} onClick={props.handleShowEditForm}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default UpdateReleaseForm;
