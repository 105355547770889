import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Select } from "antd";
import "../../../required.css";
import { protectedAxiosIntance } from "../../../API/Base";
import { LeftCircleOutlined } from "@ant-design/icons";
import { countries } from "../../../countries";

const AddCustomerForm = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [roles, setRoles] = useState([]);
  const [customer, setCustomer] = useState({
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    countryId: "",
    phone: "",
    rolesId: [],
  });

  useEffect(() => {
        protectedAxiosIntance()
          .get("roles/getRoles")
          .then((res) => setRoles(res.data.data))
          .catch((err) => {
            console.log(err);
          });

    return () => {
      props.resetSearch();
    };
  }, []);

  const handleChange = (name, value) => {
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const info = () => {
    message.success("The customer Added successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };
  const info2 = () => {
    message.error("This Email has an account already");
  };

  const onSubmit = () => {
    protectedAxiosIntance()
      .post(`customers/addCustomerFromAdmin`, {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        organization: customer.organization,
        phoneNumber: customer.phone,
        countryId: customer.countryId,
        rolesId: customer.rolesId,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          props.handleShowAddForm();
        } else if (res.data.message === "Email is exist") {
          info2();
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ backgroundColor: "#fff", borderRadius: "5px", padding: "1.5rem", marginTop: "2rem" }}>
      <LeftCircleOutlined style={{ marginBottom: "1rem", fontSize: "2.5rem", color: "#008acd" }} onClick={props.handleShowAddForm} />

      <Form
        // style={{width:"100%"}}
        name="basic"
        initialValues={{ remember: true }}
        form={form}
        onFinish={onSubmit}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        <span style={{ fontWeight: "500" }}>First Name</span>

        <Form.Item
          // className='required'
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input customer first name",
            },
            {
              pattern: "^[a-zA-Z ]*$",
              message: "The input is not valid Name!",
            },
          ]}
        >
          <Input name="firstName" placeholder="First Name" allowClear onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>
        <span style={{ fontWeight: "500" }}>Last Name</span>

        <Form.Item
          // className='required'
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input customer last name",
            },
            {
              pattern: "^[a-zA-Z ]*$",
              message: "The input is not valid Name!",
            },
          ]}
        >
          <Input name="lastName" placeholder="Last Name" allowClear onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <span style={{ fontWeight: "500" }}>Organization</span>

        <Form.Item
          // className='required'
          name="organization"
          rules={[
            {
              required: true,
              message: "Please input customer organization name",
            },
          ]}
        >
          <Input name="organization" placeholder="Organization" allowClear onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <span style={{ fontWeight: "500" }}>Country</span>

        <Form.Item
          // className='required'
          name="country"
          rules={[
            {
              required: true,
              message: "Please input customer country",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            name="country"
            style={{ width: "100%" }}
            onChange={(e) => handleChange("countryId", e)}
            optionFilterProp="children"
            placeholder="Country"
          >
            {countries.map((country) => {
              return <Option value={country.id}>{country.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <span style={{ fontWeight: "500" }}>Phone Number</span>

        <Form.Item
          // className='required'
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number",
            },
          ]}
        >
          <Input
            inputMode="numeric"
            type={"number"}
            name="phone"
            placeholder="Phone"
            allowClear
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
        <span style={{ fontWeight: "500" }}>Role</span>
        <Form.Item
          // className='required'
          name="roles"
          rules={[
            {
              required: true,
              message: "Please input customer role",
            },
          ]}
        >
          <Select name="roles" style={{ width: "100%" }} onChange={(e) => handleChange("rolesId", [e])} placeholder="Role">
            {roles.map((role) => {
              return <Option value={role.id}>{role.name}</Option>;
            })}
          </Select>
        </Form.Item>
        <span style={{ fontWeight: "500" }}>Email</span>

        <Form.Item
          // className='required'
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input allowClear name="email" placeholder="Email" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>
        <div>
          <Form.Item style={{ marginRight: "1rem", display: "inline-block", paddingLeft: "10px" }}>
            <Button
              style={{ backgroundColor: "#008acd", borderRadius: "7px", color: "#ffffff" }}
              // type="primary"
              htmlType="submit"
            >
              Add Customer
            </Button>
          </Form.Item>

          <Button
            style={{
              display: "inline-block",
              backgroundColor: "#008acd",
              borderRadius: "7px",
              color: "#ffffff",
            }}
            onClick={props.handleShowAddForm}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddCustomerForm;
