import { Button, Form, Input, Spin } from 'antd';
import React from 'react'

const LoginForm = ({onFinish, username, password, setUsername, setPassword, handleForgetPassword, isLoading}) => {
  return (
    <div className='loginForm'>
        <Form
        style={{width:'100%'}}
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            >
            <Form.Item
                name="username"
                rules={[
                {
                    required: true,
                    message: 'Please input your Username!',
                },
                ]}
            >
                <Input placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your Password!',
                },
                ]}
            >
                <Input.Password
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
            </Form.Item>
                <Form.Item>
                    <Button
                        style={{width:'100%',backgroundColor:"#008acd"}} 
                        type="primary" htmlType="submit" className="login-form-button">
                        <Spin spinning={isLoading} style={{marginRight: '1rem'}} /> Sign In
                    </Button> 
                    <a onClick={handleForgetPassword} htmltype="submit" 
                    style={{display: 'flex', justifyContent: 'right', cursor: 'pointer', fontSize:'12px',marginTop:'5px'}}>
                        Forget password</a> 
                </Form.Item>                      

        </Form>
        </div>
  )
}

export default LoginForm;