import { DropboxOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import sidlabIcon from "../../../assets/sidlab5logo.png";
import styles from "./SideNav.module.css";

const SideNav = (props) => {
  const { name } = useSelector((state) => state.profile.user.customer);

  return (
    <ul className={styles.sideNav}>
      <div style={{ paddingLeft: "1rem", paddingBottom: "4rem", paddingTop: "1rem" }}>
        <img alt="sidlabIcon" src={sidlabIcon} style={{ width: "40px" }} />
        <span style={{ marginLeft: "8px", color: "#fff", letterSpacing: "1px", fontWeight: "500" }}>SIDLAB</span>
      </div>
      <li className={`${styles.sideNavItem} ${props.view === 6 ? styles.sideNavItemActive : ""}`}>
        <button onClick={() => props.handleClick(6)} className={styles.sideNavBtn}>
          <i style={{ marginRight: "1rem" }}>
            <UserOutlined />
          </i>{" "}
          {name}
        </button>
      </li>
      <li className={`${styles.sideNavItem} ${props.view === 7 ? styles.sideNavItemActive : ""}`}>
        <button onClick={() => props.handleClick(7)} className={styles.sideNavBtn}>
          <i style={{ marginRight: "1rem" }}>
            <DropboxOutlined />
          </i>{" "}
          Licenses
        </button>
      </li>
      {props.isCustomer === "Customer" && (
        <li className={`${styles.sideNavItem} ${props.view === 8 ? styles.sideNavItemActive : ""}`}>
          <button onClick={() => props.handleClick(8)} className={styles.sideNavBtn}>
            <i style={{ marginRight: "1rem" }}>
              <LoginOutlined />
            </i>{" "}
            Quotation
          </button>
        </li>
      )}
      {props.isCustomer !== "Customer" && (
        <li className={`${styles.sideNavItem} ${props.view === 1 ? styles.sideNavItemActive : ""}`}>
          <button onClick={() => props.handleClick(1)} className={styles.sideNavBtn}>
            <i style={{ marginRight: "1rem" }}>
              <UserOutlined />
            </i>{" "}
            Customers
          </button>
        </li>
      )}
      {props.isCustomer === "Admin" && (
        <>
          <li className={`${styles.sideNavItem} ${props.view === 2 ? styles.sideNavItemActive : ""}`}>
            <button onClick={() => props.handleClick(2)} className={styles.sideNavBtn}>
              <i style={{ marginRight: "1rem" }}>
                <DropboxOutlined />
              </i>{" "}
              Releases
            </button>
          </li>
          <li className={`${styles.sideNavItem} ${props.view === 3 ? styles.sideNavItemActive : ""}`}>
            <button onClick={() => props.handleClick(3)} className={styles.sideNavBtn}>
              <i style={{ marginRight: "1rem" }}>
                <LoginOutlined />
              </i>{" "}
              Log
            </button>
          </li>
        </>
      )}
      {props.isCustomer === "Sales" && (
        <>
          <li className={`${styles.sideNavItem} ${props.view === 2 ? styles.sideNavItemActive : ""}`}>
            <button onClick={() => props.handleClick(2)} className={styles.sideNavBtn}>
              <i style={{ marginRight: "1rem" }}>
                <DropboxOutlined />
              </i>{" "}
              Releases
            </button>
          </li>
          <li className={`${styles.sideNavItem} ${props.view === 3 ? styles.sideNavItemActive : ""}`}>
            <button onClick={() => props.handleClick(3)} className={styles.sideNavBtn}>
              <i style={{ marginRight: "1rem" }}>
                <LoginOutlined />
              </i>{" "}
              Log
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default SideNav;
