import React, { useState } from "react";
import LogTableHeader from "./log-table/LogTableHeader";
import { Input } from "antd";

const Log = (props) => {
  const { Search } = Input;
  const [searchedValue, setSearchedValue] = useState("");
  return (
    <div className="scrolling" style={{ width: "95%", marginTop: "5rem", overflowY: "scroll" }}>
      <Search
        placeholder="input search text"
        style={{ width: 200, marginBottom: "1rem" }}
        onChange={(e) => setSearchedValue(e.target.value.toLowerCase())}
        allowClear
      />
      <LogTableHeader tokenExpired={props.tokenExpired} searchedValue={searchedValue} />
    </div>
  );
};

export default Log;
