import React, { useState, useEffect, Fragment } from 'react';
import {Form, Button, Input, message, Spin} from 'antd';
import { useHistory} from "react-router-dom";
import '../login/Login.css';
import { axiosIntance, Base, protectedAxiosIntance } from '../API/Base';
import icon from '../../assets/SplashScreen.png';

const Activated = () => {
    const history = useHistory();
    const [statusCode, setstatusCode] = useState(0);
    const [message, setMessage] = useState();
    useEffect(() => {
      axiosIntance.get(`/customers/confirm-account${history.location.search}`).then(res=>{
        if(res.data.statusCode==="INTERNAL_SERVER_ERROR"){
            setstatusCode(1)
            setMessage(res.data.message)  
        }
        else if(res.data.statusCode==="OK"){
            setstatusCode(2)    
        }
        else if(res.data.statusCode==="LOCKED"){
            setstatusCode(3)
        }
      }).catch(err=>{
        setstatusCode(1)
      })
     
    }, []);

      const handleClick=()=>{
        history.push('/');

      }  
      const handleResend=()=>{
        axiosIntance.get(`/customers/resend-confirmation-email${history.location.search}`).then(res=>{
            if(res.data.statusCode==="OK"){
                setstatusCode(4)
            }
          }).catch(err=>{
            setstatusCode(1)
          })
      }
      
    return ( 
        <div style={{width:'100%', display:'flex'}}>
        <div className='loginWrapper'>
            {statusCode===1?<h1 style={{color:'#008acd'}}>{message}</h1>:
            statusCode===2?
                <Fragment>
            <h1 style={{color:'#008acd'}}>Your Account is verified successfully</h1>
             <Button style={{backgroundColor:"#008acd"}}
            onClick={handleClick}
            type="primary"  className="login-form-button">
            Go to Login
            </Button>
            </Fragment>
            : statusCode===3?
            <Fragment>
                <h1 style={{color:'#008acd', fontSize: '20px'}}> [{new Date().toLocaleTimeString()}] This link is expired !</h1>
            <h2 style={{color:'#008acd', fontSize: '20px'}}>The validation link is expired, send new validation email from
            <a style={{color:"red", fontSize: '20px'}} onClick={handleResend}> here</a> </h2>
             
            </Fragment>
            :statusCode===4?<h2 style={{color:'#008acd'}}>Please check your inbox, new validation email was send to you </h2>:null}
           
           
        
        </div>
            <div className='second'>
            <img style={{margin:'auto', width:'50%'}} src={icon} alt='sidlab'/>

            </div>
        </div>
     );
}
 
export default Activated;