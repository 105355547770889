import React, { useState } from 'react';
import {Form, Button, Input, message, Spin} from 'antd';
import './Login.css';
import {  Base, protectedAxiosIntance } from '../API/Base';

const ForgetPasswordEmail = (props) => {

    const [email, setemail] = useState('');
    const onFinish = values => {
        console.log('Success:', values);
        handleClick();
      };

    //   const apiKey = localStorage.getItem('token');

    //   useEffect(() => {
    //       if (apiKey) {
           
    //       }
          
    //   }, [apiKey]);
    const info = () => {
        message.info({content:<div>Wrong Email or Email does not exist !,
                <a style={{color:"008acd"}} onClick={()=>props.setIsSignUp(false)}> Sign in</a></div>});
        // message.error('Wrong Email or Email does not exist !');
    };

      const handleClick = () => {
          props.setIsLoading(true);
          Base.get(`/customers/reset-password?email=${email}`).then(res=>{
            if (res.data.statusCode === 'OK') {
                message.success('check your email!');
                console.log(res.data.statusCode)
                props.setIsLoading(false);
            } else {
                props.setIsLoading(false)
                info()
            }
          }).catch(err=>{
            console.log(err)
            props.setIsLoading(false)
          })
      }

    

    return (
        
               <div className='loginForm'>
               <Form
               style={{width:'100%'}}
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    >
                    <p   className="login-form" style={{display: 'flex', justifyContent: 'left', fontSize:'14px'}}>Fill in your email and press send</p>
                    <Form.Item
                        name="Email"
                           rules={[
                        {
                        type: 'email',
                        message: 'The input is not valid Email!',
                        },
                        {
                        required: true,
                        message: 'Please input customer Email!',
                        },
                        ]}
                    >
                        <Input placeholder="Email" value={email} onChange={(e) => setemail(e.target.value)} />
                    </Form.Item>


                   <Form.Item>
                            <Button
                                style={{width:'100%',backgroundColor:"#008acd"}} 
                               type="primary" htmlType="submit" className="login-form-button">
                                <Spin 
                                    spinning={props.isLoading} 
                                    style={{marginRight: '1rem'}} /> Send
                            </Button>
                       <a onClick={props.handleForgetPassword} htmlType="submit"
                          style={{display: 'flex', justifyContent: 'right', cursor: 'pointer', fontSize:'12px',marginTop:'5px'}}>
                           Back To Login</a>
                        </Form.Item>                      

                </Form>
               </div>
              
        
    )
}

export default ForgetPasswordEmail;
