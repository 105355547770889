import React, { useEffect, useState } from "react";
import { EditOutlined, LeftCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Image, Input, message, Row, Select, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { protectedAxiosIntance } from "../../API/Base";
import { setProfile } from "../../../actions/Profile";
import ImageUploading from "react-images-uploading";
import styles from "./CustomerProfile.module.css";
import { countries } from "../../countries";

const { Option } = Select;

const CustomerProfile = (props) => {
  const customer = useSelector((state) => state.profile.user.customer);
  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({
    firstName: customer.firstName,
    lastName: customer.lastName,
    name: customer.name,
    email: customer.email,
    organization: customer.organization,
    countryId: customer.country.id,
    phoneNumber: customer.phoneNumber,
  });

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const savedCustomer = useSelector((state) => state.profile.user.customer);
  const [editPersonalForm, setEditPersonalForm] = useState(false);
  const handleEditPersonalForm = () => {
    setEditPersonalForm(!editPersonalForm);
  };
  const customerProfile = useSelector((state) => state.profile.customerPersonalInfo);

  const editRow = () => {
    setCurrentCustomer({
      firstName: customer.firstName,
      lastName: customer.lastName,
      name: customer.name,
      email: customer.email,
      organization: customer.organization,
      country: customer.country.name,
      countryId: customer.country.id,
      phoneNumber: customer.phoneNumber,
    });
  };

  const handleChange = (name, value) => {
    setCurrentCustomer((prev) => ({ ...prev, [name]: value }));
  };
  const info = () => {
    message.success("Your profile updated successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");

  const onChange = (imageList) => {
    const formData = new FormData();

    // Update the formData object
    formData.append("imageFile", imageList[0].file, imageList[0].file.name);
    protectedAxiosIntance()
      .post(`customers/uploadImage/${savedCustomer.id}`, formData, { observe: "response" })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          protectedAxiosIntance()
            .post(`customers/getCustomerData`, {
              id: savedCustomer.id,
              email: savedCustomer.email,
            })
            .then((res) => {
              dispatch(setProfile(res.data.data));
            });
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        message.error("image size must be less than 1MB and image formats only");
        console.log(err);
      });
  };

  const updateCustomer = (e) => {
    setButtonDisabled(true);
    setLoading(true);
    protectedAxiosIntance()
      .post(`customers/updatePersonalInfo`, {
        firstName: currentCustomer.firstName,
        lastName: currentCustomer.lastName,
        email: currentCustomer.email,
        organization: currentCustomer.organization,
        phoneNumber: currentCustomer.phoneNumber,
        countryId: currentCustomer.countryId,
        oldPassword: oldPass,
        newPassword: newPass,
        confirmPassword: conPass,
      })
      .then((res) => {
        setButtonDisabled(false);
        setLoading(false);
        if (res.data.statusCode === "OK") {
          info();
          console.log(res.data.data);
          dispatch(setProfile(res.data.data));
          handleEditPersonalForm();
          setLoading(false);
        } else {
          info1();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  let retrievedImage = "data:image/jpeg;base64," + savedCustomer.imageByte;
  return editPersonalForm ? (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #d3d3d3",
        borderRadius: "5px",
        padding: "1.5rem",
        marginTop: "2rem",
      }}
    >
      <LeftCircleOutlined style={{ marginBottom: "1rem", fontSize: "2.5rem", color: "#008acd" }} onClick={handleEditPersonalForm} />

      <Form
        name="basic"
        initialValues={{
          firstName: currentCustomer.firstName,
          lastName: currentCustomer.lastName,
          organization: currentCustomer.organization,
          country: currentCustomer.country,
          phoneNumber: currentCustomer.phoneNumber,
          email: savedCustomer.email,
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        form={form}
        onFinish={updateCustomer}
      >
        <Spin spinning={loading} tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}>
          <span style={{ fontWeight: "500" }}>First Name</span>

          <Form.Item
            // className='required'
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first name",
              },
              {
                pattern: "^[a-zA-Z ]*$",
                message: "The input is not valid Name!",
              },
            ]}
          >
            <Input
              name="firstName"
              allowClear
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              placeholder="First Name"
              defaultValue={currentCustomer.firstName}
              value={currentCustomer.firstName}
            />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Last Name</span>

          <Form.Item
            // className='required'
            name="lastName"
            rules={[
              {
                pattern: "^[a-zA-Z ]*$",
                message: "The input is not valid Name!",
              },
              {
                required: true,
                message: "Please input your last name",
              },
            ]}
          >
            <Input
              allowClear
              name="lastName"
              placeholder="Last Name"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              defaultValue={currentCustomer.lastName}
              value={currentCustomer.lastName}
            />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Organization</span>

          <Form.Item
            // className='required'
            name="organization"
            rules={[
              {
                required: true,
                message: "Please input customer organization name",
              },
            ]}
          >
            <Input
              name="organization"
              placeholder="Organization"
              allowClear
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              defaultValue={currentCustomer.organization}
              value={currentCustomer.organization}
            />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Country</span>

          <Form.Item
            // className='required'
            name="country"
            rules={[
              {
                required: true,
                message: "Please input customer country",
              },
            ]}
          >
            <Select
              showSearch
              name="country"
              style={{ width: "100%" }}
              onChange={(e) => handleChange("countryId", e)}
              optionFilterProp="children"
              placeholder="Country"
              defaultValue={currentCustomer.country}
            >
              {countries.map((country, key) => {
                return (
                  <Option key={key} value={country.id}>
                    {country.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {props.isCustomer === "Distributor" && (
            <>
              <span style={{ fontWeight: "500" }}>Distribution Area</span>

              <Form.Item
                name="distributionArea"
                rules={[
                  {
                    required: true,
                    message: "Please input Distribution Area",
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled
                  mode="multiple"
                  name="distributionArea"
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange("distributionAreaId", e)}
                  optionFilterProp="children"
                  placeholder="Area"
                  defaultValue={currentCustomer.distributionArea}
                >
                  {countries.map((distributionArea, key) => {
                    return (
                      <Option key={key} value={distributionArea.id}>
                        {distributionArea.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          {/* 
           
          {props.isCustomer === "Admin" &&
            <>
              <span style={{ fontWeight: "500" }}>Distribution Area</span>

              <Form.Item

                name="distributionArea"
                rules={[
                  {
                    required: true,
                    message: "Please input Distribution Area",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  name="distributionArea"
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange("distributionAreaId", e)}
                  optionFilterProp="children"
                  placeholder="Area"
                  defaultValue={currentCustomer.distributionArea}
                >
                  {countries.map((distributionArea, key) => {
                    return (
                      <Option key={key} value={distributionArea.id}>
                        {distributionArea.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          } */}
          <span style={{ fontWeight: "500" }}>Phone</span>

          <Form.Item
            // className='required'
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input
              inputMode="numeric"
              type={"number"}
              name="phoneNumber"
              placeholder="Phone"
              allowClear
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              defaultValue={currentCustomer.phoneNumber}
              value={currentCustomer.phoneNumber}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Email</span>
          <Form.Item
            // className='required'
            name="email"
          >
            <Input name="email" disabled defaultValue={savedCustomer.email} placeholder="Email" />
          </Form.Item>
          <span style={{ fontWeight: "500" }}>Old Password</span>

          <Form.Item
            name="oldPassword"
            rules={[
              () => ({
                validator(_, value) {
                  if (value.match("(?=.{8,})") || value === "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("password must be 8 or more characters"));
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Old Password"
              autoComplete="new-password"
              onChange={(e) => {
                //handleRequired(e.target.value())
                setOldPass(e.target.value);
              }}
            />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>New Password</span>

          <Form.Item
            name="newPassword"
            rules={[
              () => ({
                validator(_, value) {
                  if (value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})") || value === "") {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "password must contain at least one number, one uppercase and lowercase letter, symbol and 8 or more characters"
                    )
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="New Password"
              onChange={(e) => {
                setNewPass(e.target.value);
              }}
            />
          </Form.Item>
          <span style={{ fontWeight: "500" }}>Confirmation New Password</span>

          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" onChange={(e) => setConPass(e.target.value)} />
          </Form.Item>

          <div style={{ display: "flex" }}>
            <Form.Item>
              <Button
                disabled={isButtonDisabled}
                style={{
                  paddingRight: "3rem",
                  paddingLeft: "3rem",
                  borderRadius: "5px",
                  backgroundColor: "#008acd",
                }}
                type="primary"
                htmlType="submit"
              >
                {isButtonDisabled ? "Updating..." : "Update Customer"}
              </Button>
            </Form.Item>
            <Button style={{ marginLeft: "5px" }} onClick={handleEditPersonalForm}>
              Cancel
            </Button>
          </div>
        </Spin>
      </Form>
    </div>
  ) : (
    <div style={{ marginTop: "2rem" }}>
      <Row style={{ margin: "2rem 0", justifyContent: "center" }}>
        {savedCustomer.imageName === null ? (
          <Avatar size={128} icon={<UserOutlined />} />
        ) : (
          <Image width={128} height={128} style={{ borderRadius: "50%" }} src={retrievedImage}></Image>
        )}
        <ImageUploading onChange={(e) => onChange(e)} acceptType={["jpg", "png"]} maxFileSize={1048576} maxNumber={1} multiple={false}>
          {({ onImageUpload, errors }) => (
            // write your building UI
            <div className={`imageuploader ${styles.editPic}`}>
              <div className="mainBtns">
                <Tooltip placement="bottomLeft" title="Change Image" arrowPointAtCenter>
                  <i style={{ cursor: "pointer" }} onClick={onImageUpload}>
                    <EditOutlined />
                  </i>
                </Tooltip>
              </div>
              {errors && (
                <div style={{ color: "red" }}>
                  {errors.maxNumber && <span>Number of selected images exceed 1 image</span>}
                  {errors.acceptType && <span>Your selected file type is not allow only (jpg , png)</span>}
                  {errors.maxFileSize && <span>Selected file size exceed 1MB</span>}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </Row>
      <Row className="tViewRowHeader">
        <Col span={8}>Personal Information</Col>
        <Col span={15} className="tViewRowCell" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Tooltip placement="topRight" title="Edit Personal Information" arrowPointAtCenter>
            <i
              style={{ cursor: "pointer", fontSize: "1.4rem" }}
              onClick={() => {
                handleEditPersonalForm();
                editRow();
              }}
            >
              <EditOutlined />
            </i>
          </Tooltip>
        </Col>
      </Row>
      <Row className="tViewRow">
        {customerProfile &&
          Object.entries(customerProfile).map(([k, v]) => {
            const key = k.replace(/([A-Z])/g, " $1");
            const keyInSentenceCase = key.charAt(0).toUpperCase() + key.slice(1);
            return [
              <Col key={1} span={9} style={{ fontWeight: "500" }} className="tViewRowCell">
                {keyInSentenceCase}
              </Col>,
              <Col key={2} span={15} className="tViewRowCell">
                {v}
              </Col>,
            ];
          })}
      </Row>
    </div>
  );
};

export default CustomerProfile;
