import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message } from "antd";
import "../../../required.css";
import { protectedAxiosIntance } from "../../../API/Base";
import styles from "./AddReleaseForm.module.css";

const AddReleaseForm = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const initialFormState = {
    id: null,
    name: "",
    date: "",
    sidlabDownloadLink: "",
    fnlDownloadLink: "",
    matlabRuntimeDownloadLink: "",
    notes: "",
  };

  const [release, setRelease] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRelease({ ...release, [name]: value });
  };

  const handleDateChange = (date, dateString) => {
    setRelease({ ...release, date: dateString });
  };
  const info = () => {
    message.success("The Version added successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };
  const onFinish = () => {
    protectedAxiosIntance()
      .post(`versions/addVersion`, {
        name: release.name,
        date: release.date,
        sidlabDownloadLink: release.sidlabDownloadLink,
        fnlDownloadLink: release.fnlDownloadLink,
        matlabRuntimeDownloadLink: release.matlabRuntimeDownloadLink,
        notes: release.notes,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          form.resetFields();
          props.handleShowAddForm();
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "#fff", border: "1px solid #d3d3d3", borderRadius: "5px", padding: "1.5rem" }}>
        <Form name="basic" initialValues={{ remember: true }} form={form} onFinish={onFinish}>
          <span style={{ fontWeight: "500" }}>Version</span>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input Release Version",
              },
              () => ({
                validator(_, value) {
                  let pattern = new RegExp(/^\d+(\.\d+){1,3}$/);
                  if (pattern.test(value) || !value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Release Version must contain only numbers and dots"));
                },
              }),
            ]}
          >
            <Input name="name" value={release.name} onChange={handleInputChange} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Release date</span>
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Please input release date",
              },
            ]}
          >
            <DatePicker
              name="date"
              value={release.renewalDate}
              placeholder=""
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              onChange={handleDateChange}
            />
          </Form.Item>
          <span style={{ fontWeight: "500" }}>SIDLAB Download Link</span>
          <Form.Item name="sidlabDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="sidlabDownloadLink" onChange={handleInputChange} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>FNL Download Link</span>
          <Form.Item name="fnlDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="fnlDownloadLink" onChange={handleInputChange} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>MATLAB Download Link</span>
          <Form.Item name="matlabRuntimeDownloadLink" rules={[{ required: true, message: "Please add the Download Link" }]}>
            <Input name="matlabRuntimeDownloadLink" onChange={handleInputChange} />
          </Form.Item>

          <span style={{ fontWeight: "500" }}>Notes</span>
          <Form.Item name="notes">
            <TextArea name="notes" value={release.note} onChange={handleInputChange} />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Button
              className={styles.addReleaseBtn}
              style={{
                paddingRight: "3rem",
                paddingLeft: "3rem",
                borderRadius: "5px",
                marginRight: "1rem",
              }}
              type="primary"
              htmlType="submit"
            >
              Add release
            </Button>
            <Button style={{ borderRadius: "5px" }} onClick={props.handleShowAddForm}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default AddReleaseForm;
