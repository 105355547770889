import React, { useEffect, useState } from "react";
import { Button, Input, message, Select, Spin } from "antd";
import AddCustomerForm from "./customers-table/AddCustomerForm";
import UpdateCustomersForm from "./customers-table/UpdateCustomerForm";
import CustomerViewTable from "./customers-table/CustomerViewTable";
import "../../../App.css";
import SidlabTable from "../../shared/table/SidlabTable";
import { customersConstants } from "./customersConstants";
import { axiosIntance, protectedAxiosIntance } from "../../API/Base";
//for export to excel
import * as XLSX from "xlsx/xlsx.mjs";
import saveAs from "file-saver";
import AddLicense from "../licenses/AddLicense";
import { useSelector } from "react-redux";
import styles from "./customers.modules.css";

const Customers = (props) => {
  const { Search } = Input;
  const [query, setQuery] = useState("");
  const keys = ["name", "email", "role"];
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editPersonalForm, setEditPersonalForm] = useState();
  const [editLicenseForm, setEditLicenseForm] = useState();
  const [viewCustomer, setViewCustomer] = useState(false);
  const [addLicense, setAddLicense] = useState(false);
  const customer = useSelector((state) => state.profile.user.customer);
  const adminRole = customer.roles.find((role) => role.name === "Admin");
  const [loadingLicenseInfo, setLoadingLicenseInfo] = useState(false);
  const { OptGroup } = Select;
  const initialFormState = {
    id: null,
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    organization: "",
    Country: "",
    phone: "",
    licenseInfo: [],
    roles: [],
    teacher: false,
    requestTrial: false,
    offlineLicenseAllowed: false,
    distributionArea: [],
    note: "",
    noteId: 0,
  };
  const [currentCustomer, setCurrentCustomer] = useState(initialFormState);
  const [types, setTypes] = useState();
  const [category, setCategory] = useState();
  const [versions, setVersions] = useState();
  const [modules, setModules] = useState();
  const [loading, setLoading] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState([]);
  const { Option } = Select;

  const [customersData, setCustomersData] = useState([]);
  useEffect(() => {
    if (!dataChanged) {
      setLoading(true);
      protectedAxiosIntance()
        .get("customers/getCustomers")
        .then((res) => {
          if (res.data.statusCode === "OK") {
            setCustomersData(res.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            message.error("Error Please Reload");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      protectedAxiosIntance()
        .get("types/getTypes")
        .then((res) => {
          if (res.data.statusCode === "OK") {
            setTypes(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      protectedAxiosIntance()
        .get("categories/getCategories")
        .then((res) => {
          if (res.data.statusCode === "OK") {
            setCategory(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      axiosIntance
        .get("versions/getVersions")
        .then((res) => {
          if (res.data.statusCode === "OK") {
            setVersions(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      protectedAxiosIntance()
        .get("modules/getModules")
        .then((res) => {
          if (res.data.statusCode === "OK") {
            setModules(res.data.data.moduleModels);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (currentCustomer.id !== null) {
        editRow(currentCustomer.id);
      }
    }
  }, [showAddForm, showEditForm, addLicense]);

  const [latestVersion, setLatestversion] = useState();
  const getLatest = () => {
    versions.map((version) => {
      if (version.latest === true) {
        // form.setFieldValue('version', version.id)
        setLatestversion(version.id);
      }
    });
  };

  //search fn
  const search = (data) => {
    return data.filter((custom) => keys.some((key) => custom[key].toLowerCase().replaceAll(" ", "").includes(query.toLowerCase())));
  };

  const filteredData = (e) => {
    setLoading(true);
    setSelectedMaintenance(e);
    setDataChanged(true);

    const filterObj = {
      role: [],
      license: [],
      status: [],
      maintstat: [],
    };
    e.map((val) => {
      if (val.includes("role:")) {
        filterObj.role.push(val.replace("role:", ""));
      } else if (val.includes("license:")) {
        filterObj.license.push(val.replace("license:", ""));
      } else if (val.includes("status:")) {
        if (val.includes("Activated")) {
          filterObj.status.pop(val);
          filterObj.status.push(val.replace("status:", ""));
        } else {
          filterObj.status.pop(val);
          filterObj.status.push(val.replace("status:", ""));
        }
      } else if (val.includes("maintstat:")) {
        if (val.includes("In maintenance")) {
          filterObj.maintstat.pop(val);
          filterObj.maintstat.push(val.replace("maintstat:", ""));
        } else {
          filterObj.maintstat.pop(val);
          filterObj.maintstat.push(val.replace("maintstat:", ""));
        }
      }
    });
    protectedAxiosIntance()
      .post("customers/filter-customers", filterObj)
      .then((res) => {
        setCustomersData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleShowAddForm = () => {
    setShowAddForm(!showAddForm);
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    wb.props = {
      Title: "Customers",
      Author: "SIDLAB Portal",
    };
    wb.SheetNames.push("Sheet1");
    let tempXls = [["id", "name", "email", "role"]];
    let ws = XLSX.utils.aoa_to_sheet(
      tempXls.concat(
        search(customersData).map((customer) => Object.values([customer?.id, customer?.name, customer?.email, customer?.role]))
      )
    );
    wb.Sheets["Sheet1"] = ws;
    const wbOutput = XLSX.write(wb, { bookType: "csv", type: "binary" });
    saveAs(new Blob([s2ab(wbOutput)], { type: "application/octet-stream" }), "customers.csv");
  };

  const handleShowEditForm = () => {
    setShowEditForm(!showEditForm);
    setEditPersonalForm(false);
    setEditLicenseForm(false);
  };

  const handleEditPersonalForm = () => {
    setShowEditForm(!showEditForm);
    setEditPersonalForm(true);
  };

  const handleAddLicenseForm = () => {
    getLatest();
    setAddLicense(!addLicense);
  };

  const handleEditLicenseForm = () => {
    setShowEditForm(!showEditForm);
    setEditLicenseForm(true);
  };

  const handleViewCustomer = () => {
    setViewCustomer(!viewCustomer);
  };

  const addCustomer = (customer) => {
    customer.id = customersData.length + 1;
    setCustomersData([...customersData, customer]);
  };

  const resetSearch = () => {
    setQuery("");
  };

  const deleteCustomer = (id) => {
    protectedAxiosIntance()
      .delete(`customers/deleteCustomer/${id}`)
      .then((res) => {
        setCustomersData(customersData.filter((customer) => customer.id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editRow = (customerId) => {
    setLoadingLicenseInfo(true);
    protectedAxiosIntance()
      .get(`customers/findCustomer/${customerId}`)
      .then((res) => {
        setLoadingLicenseInfo(false);
        setCurrentCustomer({
          id: res.data.data.id,
          name: res.data.data.name,
          firstName: res.data.data.firstName,
          lastName: res.data.data.lastName,
          email: res.data.data.email,
          organization: res.data.data.organization,
          country: res.data.data.country.name,
          countryId: res.data.data.country.id,
          phoneNumber: res.data.data.phoneNumber,
          roles: res.data.data.roles,
          licenses: res.data.data.licenses,
          teacher: res.data.data.teacher,
          requestTrial: res.data.data.requestTrial,
          offlineLicenseAllowed: res.data.data.offlineLicenseAllowed,
          distributionArea: res.data.data.distributionArea,
        });
      })
      .then(() =>
        protectedAxiosIntance()
          .post(`customers/get-note`, customerId)
          .then((res) => {
            if (res.data.statusCode === "OK") {
              setCurrentCustomer((prev) => ({
                ...prev,
                note: res.data.data.note,
                noteId: res.data.data.id,
              }));
            } else {
              setCurrentCustomer((prev) => ({ ...prev, note: "", noteId: 0 }));
            }
          })
      )
      .catch((err) => {
        setLoadingLicenseInfo(false);
        console.log(err);
      });
  };

  const updateCustomer = (id, updatedCustomer, info) => {
    if (info) {
      const editedCustomer = {
        ...updatedCustomer,
        city: null,
        rolesId: updatedCustomer.roles.map((role) => role.id),
      };
      delete editedCustomer.roles;
      delete editedCustomer.country;
      delete editedCustomer.licenses;
      protectedAxiosIntance()
        .put("customers/updateCustomer", editedCustomer)
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
        });
      setCustomersData(customersData.map((customer) => (customer.id === id ? updatedCustomer : customer)));
    } else {
      protectedAxiosIntance()
        .put("licenses/updateLicense", currentCustomer.licenseInfo)
        .then((res) => console.log(res))
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let content = null;
  if (showAddForm) {
    content = (
      <AddCustomerForm
        tokenExpired={props.tokenExpired}
        addCustomer={addCustomer}
        handleShowAddForm={handleShowAddForm}
        resetSearch={resetSearch}
      />
    );
  } else if (addLicense) {
    content = (
      <AddLicense
        currentCustomer={currentCustomer}
        types={types}
        category={category}
        versions={versions}
        modules={modules}
        latestVersion={latestVersion}
        handleAddLicenseForm={handleAddLicenseForm}
        resetSearch={resetSearch}
      />
    );
  } else if (showEditForm) {
    content = (
      <UpdateCustomersForm
        modules={modules}
        versions={versions}
        currentCustomer={currentCustomer}
        editPersonalForm={editPersonalForm}
        editLicenseForm={editLicenseForm}
        tokenExpired={props.tokenExpired}
        updateCustomer={updateCustomer}
        handleShowEditForm={handleShowEditForm}
        handleViewCustomer={handleViewCustomer}
        handleAddLicenseForm={handleAddLicenseForm}
        editRow={editRow}
        resetSearch={resetSearch}
        isCustomer={props.isCustomer}
      />
    );
  } else if (viewCustomer) {
    content = (
      <CustomerViewTable
        loadingLicenseInfo={loadingLicenseInfo}
        currentCustomer={currentCustomer}
        setCurrentCustomer={setCurrentCustomer}
        tokenExpired={props.tokenExpired}
        handleEditPersonalForm={handleEditPersonalForm}
        handleViewCustomer={handleViewCustomer}
        handleEditLicenseForm={handleEditLicenseForm}
        handleAddLicenseForm={handleAddLicenseForm}
        resetSearch={resetSearch}
      />
    );
  } else {
    content = [
      <div key={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Search
          key={1}
          placeholder="input search text"
          style={{ width: "40%", marginBottom: "1rem" }}
          onChange={(e) => setQuery(e.target.value)}
          allowClear
        />

        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          mode="multiple"
          style={{
            width: "60%",
            marginBottom: "1rem",
            borderRadius: "9px",
            maxTagCount: "responsive",
            height: "30%",
          }}
          placeholder=" Filter Items"
          maxTagCount="responsive"
          allowClear
          onChange={(e) => filteredData(e, customersData)}
          value={selectedMaintenance}
        >
          <OptGroup label="Role">
            <Option value="role:Admin">Admin</Option>
            <Option value="role:Customer">Customer</Option>
            <Option value="role:Distributor">Distributor</Option>
            <Option value="role:Sales">Sales</Option>
          </OptGroup>

          <OptGroup label="License Types">
            <Option
              value="license:CPU - Trial"
              hidden={
                selectedMaintenance.includes("maintstat:In maintenance") || selectedMaintenance.includes("maintstat:Out of maintenance")
              }
            >
              {" "}
              CPU - Trial
            </Option>
            <Option
              value="license:CPU - Temporary"
              hidden={
                selectedMaintenance.includes("maintstat:In maintenance") || selectedMaintenance.includes("maintstat:Out of maintenance")
              }
            >
              CPU - Temporary
            </Option>
            <Option
              value="license:CPU - Monthly"
              hidden={
                selectedMaintenance.includes("maintstat:In maintenance") || selectedMaintenance.includes("maintstat:Out of maintenance")
              }
            >
              CPU - Monthly
            </Option>
            <Option value="license:CPU - Permanent">CPU - Permanent</Option>
            <Option
              value="license:FNL - Temporary"
              hidden={
                selectedMaintenance.includes("maintstat:In maintenance") || selectedMaintenance.includes("maintstat:Out of maintenance")
              }
            >
              FNL - Temporary
            </Option>
            <Option value="license:FNL - Permanent">FNL - Permanent</Option>
          </OptGroup>
          <OptGroup label="Status">
            <Option value="status:Activated">Activated</Option>
            <Option value="status:Deactivated">Deactivated</Option>
          </OptGroup>

          <OptGroup label="Maintenance status">
            <Option value="maintstat:In maintenance">In maintenance</Option>
            <Option value="maintstat:Out of maintenance">Out of maintenance</Option>
          </OptGroup>
        </Select>
      </div>,
      <Spin
        key={2}
        spinning={loading}
        tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}
        size="large"
        className={styles.spinner}
      >
        <SidlabTable data={search(customersData)} cols={customersConstants(deleteCustomer, handleViewCustomer, editRow, adminRole)} />
      </Spin>,
      <div key={3} style={{ marginTop: "2rem" }}>
        {adminRole && (
          <Button
            type="primary"
            style={{ marginRight: "1rem", borderRadius: "7px", backgroundColor: "#008acd" }}
            onClick={handleShowAddForm}
          >
            Add
          </Button>
        )}
        <Button type="primary" style={{ borderRadius: "7px", backgroundColor: "#008acd" }} onClick={handleExport}>
          Export
        </Button>
      </div>,
    ];
  }

  return (
    <div
      style={{
        display: viewCustomer ? "flex" : "",
      }}
    >
      <div
        className="scrolling"
        style={{
          width: "100%",
          paddingTop: "2rem",
          marginRight: "2rem",
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default Customers;
