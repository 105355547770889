import { SET_PROFILE, LOGOUT, ADD_TRIAL } from "../actions/actiontypes";

export default function profile(
  state = {
    token: null,
    user: {},
    customerPersonalInfo: {},
    customerLicenses: [],
  },
  action
) {
  switch (action.type) {
    case SET_PROFILE:
      console.log(action);
      return {
        ...state,
        token: action.userProfile.access_token,
        user: action.userProfile,
        customerPersonalInfo: {
          firstName: action.userProfile.customer.firstName,
          lastName: action.userProfile.customer.lastName,
          email: action.userProfile.customer.email,
          organization: action.userProfile.customer.organization,
          country: action.userProfile.customer.country.name,
          phone: action.userProfile.customer.phoneNumber,
        },
        customerLicenses: action.userProfile.licenses.map((license) => {
          if (license.licenseTypeModel.name === "Trial") {
            return {
              licenseCategory: license.licenseCategoryModel?.name,
              licenseType: license.licenseTypeModel?.name,
              startDate: license.startDate,
              endDate: license.endDate,
              eligibleVersion: license.version,
              modules: license.moduleModels,
              MACAddress: license.macAddresses,
            };
          } else if (license.licenseTypeModel.name === "Student") {
            return {
              licenseCategory: license.licenseCategoryModel?.name,
              licenseType: license.licenseTypeModel?.name,
              startDate: license.startDate,
              endDate: license.endDate,
              eligibleVersion: license.version,
              MACAddress: license?.macAddresses,
              modules: license.moduleModels,
              courseCode: license.courseCode,
            };
          } else if (license.licenseTypeModel.name === "Permanent") {
            return {
              licenseCategory: license.licenseCategoryModel?.name,
              licenseType: license.licenseTypeModel?.name,
              purchaseDate: license.purchaseDate,
              //    subscriptionYears: '',
              subscriptionStartDate: license.licenseSubscriptionModels[0].subscriptionStartDate,
              subscriptionEndDate: license.licenseSubscriptionModels[0].subscriptionEndDate,
              months: license.licenseSubscriptionModels[0].months,
              renewalDate: license.renewDate,
              eligibleVersion: license.version,
              MACAddress: license.macAddresses,
              //    activationSN: license.serialNo,
              //    MATLABFiles: license.matlabFilesAccess,
              modules: license.moduleModels,
              numberOfSeats: license.seats,
            };
          } else if (license.licenseTypeModel.name === "Monthly") {
            return {
              licenseCategory: license.licenseCategoryModel?.name,
              licenseType: license.licenseTypeModel?.name,
              startDate: license.startDate,
              endDate: license.endDate,
              months: license.months,
              eligibleVersion: license.version,
              MACAddress: license.macAddresses,
              // activationSN: license.serialNo,
              // MATLABFiles: license.matlabFilesAccess,
              modules: license.moduleModels,
              // numberOfMonths: 5,
            };
          } else if (license.licenseTypeModel.name === "Temporary") {
            return {
              licenseCategory: license.licenseCategoryModel?.name,
              licenseType: license.licenseTypeModel?.name,
              startDate: license.startDate,
              endDate: license.endDate,
              renewalDate: license.renewDate,
              eligibleVersion: license.version,
              MACAddress: license.macAddresses,
              modules: license.moduleModels,
              numberOfSeats: license.seats,
            };
          }
        }),
      };
    case ADD_TRIAL:
      console.log(action);
      return {
        ...state,
        customerLicenses: action.license.map((license) => {
          if (license.licenseTypeModel.name === "Trial") {
            return {
              licenseCategory: license.licenseCategoryModel.name,
              licenseType: license.licenseTypeModel.name,
              startDate: license.startDate,
              endDate: license.endDate,
              eligibleVersion: license.version,
              modules: license.moduleModels,
              MACAddress: license.macAddress,
            };
          } else if (license.licenseTypeModel.name === "Student") {
            return {
              licenseCategory: license.licenseCategoryModel.name,
              licenseType: license.licenseTypeModel.name,
              startDate: license.startDate,
              endDate: license.endDate,
              eligibleVersion: license.version,
              MACAddress: license.macAddress,
              modules: license.moduleModels,
              courseCode: license.courseCode,
            };
          } else if (license.licenseTypeModel.name === "Permanent") {
            return {
              licenseCategory: license.licenseCategoryModel.name,
              licenseType: license.licenseTypeModel.name,
              purchaseDate: license.purchaseDate,
              //    subscriptionYears: '',
              subscriptionStartDate: license.licenseSubscriptionModels[0].subscriptionStartDate,
              subscriptionEndDate: license.licenseSubscriptionModels[0].subscriptionEndDate,
              months: license.licenseSubscriptionModels[0].months,
              renewalDate: license.renewDate,
              eligibleVersion: license.version,
              MACAddress: license.macAddresses,
              //    activationSN: license.serialNo,
              //    MATLABFiles: license.matlabFilesAccess,
              modules: license.moduleModels,
              numberOfSeats: license.seats,
            };
          } else if (license.licenseTypeModel.name === "Monthly") {
            return {
              licenseCategory: license.licenseCategoryModel.name,
              licenseType: license.licenseTypeModel.name,
              startDate: license.startDate,
              endDate: license.endDate,
              months: license.months,
              eligibleVersion: license.version,
              MACAddress: license.macAddress,
              // activationSN: license.serialNo,
              // MATLABFiles: license.matlabFilesAccess,
              modules: license.moduleModels,
              // numberOfMonths: 5,
            };
          }
        }),
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: {},
        customerPersonalInfo: {},
        customerLicenses: [],
      };
    default:
      return state;
  }
}
