import React from "react";
import { Input } from "antd";
import styles from "./MacInput.module.css";

const MacInput = ({
  label,
  handlePaste,
  offlineLicenseData,
  setOfflineLicenseData,
  handleJumpToNext,
  isValidHexa,
  mac1Ref,
  mac2Ref,
  mac3Ref,
  mac4Ref,
  mac5Ref,
  mac6Ref,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <label style={{ width: "30%", fontWeight: "500", fontSize: "1.4rem" }}>{label}</label>
      <div className={styles.inputContainer} onKeyDown={handlePaste} onPaste={handlePaste}>
        <Input
          value={offlineLicenseData.mac1}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac2Ref, mac1Ref, mac1Ref)}
          ref={mac1Ref}
          status={isValidHexa(offlineLicenseData.mac1) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac1: e.target.value,
            });
          }}
        />
        <div className={styles.divColons}> : </div>
        <Input
          value={offlineLicenseData.mac2}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac3Ref, mac2Ref, mac1Ref)}
          ref={mac2Ref}
          status={isValidHexa(offlineLicenseData.mac2) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac2: e.target.value,
            });
          }}
        />
        <div className={styles.divColons}> : </div>
        <Input
          value={offlineLicenseData.mac3}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac4Ref, mac3Ref, mac2Ref)}
          ref={mac3Ref}
          status={isValidHexa(offlineLicenseData.mac3) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac3: e.target.value,
            });
          }}
        />
        <div className={styles.divColons}> : </div>
        <Input
          value={offlineLicenseData.mac4}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac5Ref, mac4Ref, mac3Ref)}
          ref={mac4Ref}
          status={isValidHexa(offlineLicenseData.mac4) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac4: e.target.value,
            });
          }}
        />
        <div className={styles.divColons}> : </div>
        <Input
          value={offlineLicenseData.mac5}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac6Ref, mac5Ref, mac4Ref)}
          ref={mac5Ref}
          status={isValidHexa(offlineLicenseData.mac5) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac5: e.target.value,
            });
          }}
        />
        <div className={styles.divColons}> : </div>
        <Input
          value={offlineLicenseData.mac6}
          className={styles.inputMacElement}
          onKeyDown={(e) => handleJumpToNext(e, mac6Ref, mac6Ref, mac5Ref)}
          ref={mac6Ref}
          status={isValidHexa(offlineLicenseData.mac6) ? "" : "error"}
          maxLength={2}
          onChange={(e) => {
            setOfflineLicenseData({
              ...offlineLicenseData,
              mac6: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default MacInput;
