import Axios from "axios";
// import { getLocalStorage } from "./localStorage";

export const Base = Axios.create({
  // baseURL: 'http://127.0.0.1:8080/sidlab_lm/',
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  //     // baseURL: 'http://elnadycompany.com:8080/demo',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    //         // 'Access-Control-Request-Headers': 'content-type'
    //         // 'Access-Control-Allow-Origin': '*',
    //         // 'Access-Control-Allow-credentials': true,
    //         // 'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

// export const Base1 = Axios.create({
//      baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
//      headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//          Authorization: `Bearer ${apiKey}`
//      }
//  })

export const axiosIntance = Axios.create({
  // baseURL: 'http://127.0.0.1:8080/sidlab_lm/',

  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    "Content-Type": "Application/json",
    Accept: "application/json",
  },
});

export const axiosDownload = () => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  const axiosIntance = Axios.create({
    // baseURL: 'http://127.0.0.1:8080/sidlab_lm/',
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      "Content-Type": "Application/octet-stream",
      // "Content-Disposition": "attachment; filename=sidlab-offline-license.txt", // need to enable access to use from server side
      // responseType: "blob", // need to enable access to use from server side
      Accept: "Application/octet-stream",
      Authorization: `Bearer ${token}`,
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'
    },
  });
  return axiosIntance;
};

export const protectedAxiosIntance = () => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  const axiosIntance = Axios.create({
    // baseURL: 'http://127.0.0.1:8080/sidlab_lm/',
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    headers: {
      "Content-Type": "Application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      //'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'
    },
  });
  return axiosIntance;
};
