import React, { useEffect, useState } from "react";
import { Button, Spin, Table } from "antd";
import { protectedAxiosIntance } from "../../../API/Base";
import styles from "./LogTableHeader.module.css";
import * as XLSX from "xlsx/xlsx.mjs";

const LogTableHeader = ({ searchedValue, tokenExpired }) => {
  const [logs, setLogs] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
          protectedAxiosIntance()
            .get("licenses/getLogs")
            .then((res) => {
              setLogs(
                res.data.data.map((data, i) => {
                  const dateAndTime = data.dateAndTime.split("T");
                  return {
                    key: i,
                    adminEmail: data.adminEmail,
                    licenseOwnerEmail: data.licenseOwnerEmail,
                    license:
                      data.licenseCategory === null || data.licenseType === null
                        ? "No License yet"
                        : data.licenseCategory + " - " + data.licenseType,
                    action: data.action,
                    date: dateAndTime[0],
                    time: dateAndTime[1],
                  };
                })
              );
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
  }, []);

  const colHeaders = [
    {
      title: "Admin Mail",
      dataIndex: "adminEmail",
      key: "adminEmail",
      onFilter: (value, record) =>
        record.adminEmail?.toLowerCase().includes(value) ||
        record.licenseOwnerEmail?.toLowerCase().includes(value) ||
        record.license?.toLowerCase().includes(value) ||
        record.action?.toLowerCase().includes(value),
      filteredValue: [searchedValue],
    },
    {
      title: "License Owner Mail",
      dataIndex: "licenseOwnerEmail",
      key: "licenseOwnerEmail",
    },
    {
      title: "License",
      dataIndex: "license",
      key: "license",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
  ];

  const colData = logs?.map((log) => {
    return {
      key: log.key,
      adminEmail: log.adminEmail,
      licenseOwnerEmail: log.licenseOwnerEmail,
      license: log.license,
      action: log.action,
      date: log.date,
      time: log.time,
    };
  });

  const exportToExcel = (data) => {
    const formattedData = data.map(({ date, time, action, license, licenseOwnerEmail, adminEmail }) => ({
      adminEmail,
      licenseOwnerEmail,
      license,
      action,
      date,
      time,
    }));
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "logs.xlsx");
  };

  return (
    <>
      <Button onClick={() => exportToExcel(logs)} style={{ marginLeft: "5px" }}>
        Export
      </Button>

      <Spin
        spinning={loading}
        tip={<h3 style={{ color: "#008acd", fontWeight: "400" }}>Loading ...</h3>}
        size="large"
        className={styles.spinner}
      >
        <Table columns={colHeaders} dataSource={colData} />
      </Spin>
    </>
  );
};

export default LogTableHeader;
