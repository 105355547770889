import React from "react";
import { Row, Col, Popconfirm, Typography, Tooltip, Card } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import styles from "../../../shared/table/Table.module.css";

const ReleasesTableRow = (props) => {
  const { Text } = Typography;

  const handleEdit = () => {
    props.handleShowEditForm();
    props.editRow(props.release);
  };

  return (
    <Row className="tRow" style={{ backgroundColor: "#fff", width: "100%", fontSize: "14px" }}>
      <Col className="tRowCell" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        {props.name}
      </Col>
      <Col className="tRowCell" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        {props.date}
      </Col>
      <Col className="tRowCell" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        <div className={styles.verticalLinks}>
          <a download href={props.sidlabDownloadLink}>
            SIDLAB
          </a>
          <a download href={props.fnlDownloadLink}>
            FNL
          </a>
          <a download href={props.matlabRuntimeDownloadLink}>
            MATLAB
          </a>
        </div>
      </Col>
      <Col className="tRowCell" span={7}>
        <Card className="noteArea" style={{ padding: "0" }}>
          {props.notes}
        </Card>
      </Col>
      {props.adminRole && (
        <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }} span={2}>
          <Tooltip placement="top" title="Edit Release Information">
            <i style={{ cursor: "pointer", fontSize: "1.4rem", marginRight: "1rem" }} onClick={handleEdit}>
              <EditOutlined />
            </i>
          </Tooltip>
          <Tooltip title="delete">
            <Popconfirm title="Are You Sure You Want to Delete?" onConfirm={() => props.deleteRelease(props.id)}>
              <CloseOutlined />
            </Popconfirm>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

export default ReleasesTableRow;
