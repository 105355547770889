import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Radio, Select } from "antd";
import "../../required.css";
import { protectedAxiosIntance } from "../../API/Base";
import { countries } from "../../countries";
import { connect, useSelector } from "react-redux";

const RequestQuotation = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { email } = useSelector((state) => state.profile.user.customer);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    company: "",
    department: "",
    position: "",
    phone: "",
    address: "",
    state: "",
    country: "",
    modules: [],
    licenseValidity: "",
    access: "",
    licenseUse: "",
    usersNumber: null,
    academicUse: "",
  });

  const onSubmit = () => {
    // console.log(form);
    protectedAxiosIntance()
      .post("customers/submitQuotation", {
        firstName: user.firstName,
        lastName: user.lastName,
        email: email,
        company_university: user.company,
        department_faculty: user.department,
        position: user.position,
        phone: user.phone,
        address: user.address,
        state: user.state,
        country: user.country,
        licenseValidity: user.licenseValidity,
        access: user.access,
        licenseUse: user.licenseUse,
        usersNumber: user.usersNumber,
        academicUse: user.academicUse,
        modules: user.modules,
      })
      .then((res) => {
        // console.log(res);
        if (res.data.statusCode === "OK") {
          message.success("Submitted Successfully");
        } else if (res.data.statusCode === "BAD_REQUEST") {
          message.error("Please Fill the Empty Fields Then Try Again");
        } else if (res.data.statusCode === "NOT_FOUND") {
          message.error(`${res.data.message}`);
        } else if (res.data.statusCode === "METHOD_NOT_ALLOWED") {
          message.error(`${res.data.message}`);
        } else if (res.data.statusCode === "INTERNAL_SERVER_ERROR") {
          message.error(`${res.data.message}`);
        } else if (res.status === 410) {
          message.warning(`${res.error}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (name, value) => {
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div style={{ backgroundColor: "#fff", borderRadius: "5px", padding: "1.5rem", marginTop: "2rem" }}>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        form={form}
        onFinish={onSubmit}
        onFinishFailed={() => message.error("Please Fill the Empty Fields Then Try Again")}
      >
        <Form.Item
          // className='required'
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your first name",
            },
            {
              pattern: "^[a-zA-Z]*$",
              message: "The input is not valid Name!",
            },
          ]}
        >
          <Input name="firstName" allowClear onChange={(e) => handleChange(e.target.name, e.target.value)} placeholder="First Name" />
        </Form.Item>

        <Form.Item
          // className='required'
          name="lastName"
          rules={[
            {
              pattern: "^[a-zA-Z]*$",
              message: "The input is not valid Name!",
            },
            {
              required: true,
              message: "Please input your last name",
            },
          ]}
        >
          <Input allowClear name="lastName" placeholder="Last Name" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <Form.Item
          // className='required'
          name="email"
          // rules={[
          // {
          // type: 'email',
          // message: 'The input is not valid Email!',
          // },
          // {
          // required: true,
          // message: 'Please input your email',
          // },
          // ]}
        >
          <Input name="email" disabled defaultValue={email} placeholder="Email" />
        </Form.Item>

        <Form.Item
          // className='required'
          name="company"
          rules={[
            {
              required: true,
              message: "Please input your company/university",
            },
          ]}
        >
          <Input name="company" allowClear placeholder="Company/University" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <Form.Item
          // className='required'
          name="department"
          rules={[
            {
              required: true,
              message: "Please input your Department/Faculty",
            },
          ]}
        >
          <Input
            name="department"
            allowClear
            placeholder="Department/Faculty"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Form.Item>

        <Form.Item
          // className='required'
          name="position"
          rules={[
            {
              required: true,
              message: "Please input your position",
            },
          ]}
        >
          <Input name="position" allowClear placeholder="Position" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <Form.Item
          // className='required'
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone number",
            },
          ]}
        >
          <Input
            inputMode="numeric"
            type={"number"}
            name="phone"
            placeholder="Phone"
            allowClear
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          // className='required'
          name="address"
          rules={[
            {
              required: true,
              message: "Please input your address",
            },
          ]}
        >
          <Input name="address" allowClear placeholder="Address" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <Form.Item
          // className='required'
          name="state"
          rules={[
            {
              required: true,
              message: "Please input your state",
            },
          ]}
        >
          <Input name="state" allowClear placeholder="State" onChange={(e) => handleChange(e.target.name, e.target.value)} />
        </Form.Item>

        <Form.Item
          // className='required'
          name="country"
          rules={[
            {
              required: true,
              message: "Please input customer country",
            },
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            name="country"
            style={{ width: "100%" }}
            onChange={(e) => handleChange("country", e)}
            optionFilterProp="children"
            placeholder="Country"
          >
            {countries.map((country) => {
              return <Option value={country.name}>{country.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <p style={{ fontWeight: "500" }}>Please choose SIDLAB modules of interest: </p>
        <Form.Item rules={[{ required: true, message: "Please choose SIDLAB modules of interest" }]}>
          <Checkbox.Group onChange={(e) => handleChange("modules", e)} name="modules">
            <Checkbox value="SIDLAB Acoustics Low Frequency">SIDLAB Acoustics Low Frequency</Checkbox>
            <br />
            <Checkbox value="SIDLAB Acoustics High Frequency">SIDLAB Acoustics High Frequency</Checkbox>
            <br />
            <Checkbox value="SIDLAB Flow">SIDLAB Flow</Checkbox>
            <br />
            <Checkbox value="SIDLAB Acquisition">SIDLAB Acquisition</Checkbox>
            <br />
            <Checkbox value="SIDLAB Measurement">SIDLAB Measurement</Checkbox>
            <br />
            <Checkbox value="SIDLAB Measurement Rig">SIDLAB Measurement Rig</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <p style={{ color: "#777" }}>
          For more information about products please visit: <a>https://sidlab.se/products/</a>
        </p>
        <Form.Item
          // className='required'
          name="licenseValidity"
          rules={[
            {
              required: true,
              message: "Please input your license validity",
            },
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            name="licenseValidity"
            placeholder="License Validity"
            onSelect={(e) => handleChange("licenseValidity", e)}
          >
            <Option value="Monthly Lease">Monthly Lease</Option>
            <Option value="Permanent">Permanent</Option>
          </Select>
        </Form.Item>

        <p style={{ color: "#777" }}>
          <span style={{ fontWeight: "500" }}>Monthly Lease: </span> One month access to all SIDLAB modules, compiled version. Full
          technical support included.
        </p>
        <p style={{ color: "#777" }}>
          <span style={{ fontWeight: "500" }}>Permanent: </span> lifetime access to the purchased modules. Maintanence subscription is
          included for one year.
        </p>

        <Form.Item
          // className='required'
          name="accessToSourceCode"
          rules={[
            {
              required: true,
              message: "Please input your access to source code",
            },
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            name="access"
            placeholder="Access To Source Code"
            onSelect={(e) => handleChange("access", e)}
          >
            <Option value="Full license">Full License</Option>
            <Option value="Compiled License">Compiled License</Option>
          </Select>
        </Form.Item>

        <p style={{ color: "#777" }}>
          <span style={{ fontWeight: "500" }}>Full License: </span> Possible access to the MATLAB source codes.
        </p>
        <p style={{ color: "#777" }}>
          <span style={{ fontWeight: "500" }}>Compiled License: </span> No access to the MATLAB source codes.
        </p>

        <Form.Item
          // className='required'
          name="licenseUse"
          rules={[
            {
              required: true,
              message: "Please input your license use",
            },
          ]}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            name="licenseUse"
            placeholder="License Use"
            onSelect={(e) => handleChange("licenseUse", e)}
          >
            <Option value="Computer Locked">Computer Locked</Option>
            <Option value="Floating Network License">Floating Network License</Option>
          </Select>
        </Form.Item>

        <p style={{ color: "#777" }}>
          SIDLAB may be installed and operate on up to 2 computers, providing that it is only accessible and operated by a single licensed
          user.
        </p>

        <p style={{ color: "#777" }}>
          CPU Locked Multiple User Licenses: Special discounts are available for purchasing more than one user license. The second user
          onwards gets a 50% discount.
        </p>

        <p style={{ color: "#777" }}>
          Floating Network License: Licensed per concurrent user. SIDLAB can be installed on any number of machines on the same network (or
          VPN).
        </p>

        <p style={{ color: "#777" }}>
          SIDLAB runs on local computers, with the network used only for license authentication. SIDLAB FNL Manager is installed on a server
          and provides license authentication. The price for 1 Floating Network seat is 150% of the Single User License price.
        </p>

        <Form.Item
          // className='required'
          name="usersNumber"
          rules={[
            {
              required: true,
              message: "Please input the number of users",
            },
            {
              pattern: "^([0-9][0-9]?|)$",
              message: "Please enter a number between 1 - 99",
            },
          ]}
        >
          <Input
            name="usersNumber"
            type="number"
            min={"1"}
            max={"99"}
            size="1"
            maxLength="2"
            placeholder="Number Of Users"
            onChange={(e) => handleChange(e.target.name, Number(e.target.value))}
          />
        </Form.Item>

        <p>Is this for academic use?</p>
        <Form.Item rules={[{ required: true, message: "Please Select is this for academic use?" }]}>
          <Radio.Group onChange={(e) => handleChange(e.target.name, e.target.value)} name="academicUse">
            <Radio value={true}>Yes</Radio>
            <br />
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button style={{ padding: "0 3rem", borderRadius: "5px", backgroundColor: "#008acd" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default connect()(RequestQuotation);
