import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message, Modal, Row } from "antd";
import SidlabTable from "../../../shared/table/SidlabTable";
import { codesConstants } from "./codesConstants";
import "../../../required.css";
import { protectedAxiosIntance } from "../../../API/Base";

const CustomerCourseCodes = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const info = () => {
    message.success("The Course Added successfully");
  };
  const info1 = () => {
    message.error("Something went wrong");
  };

  const handleOk = () => {
    protectedAxiosIntance()
      .post(`courses/createCourse`, {
        startDate: courseRequest.startDate,
        numberOfDays: courseRequest.days,
        customerId: props.customer.id,
      })
      .then((res) => {
        if (res.data.statusCode === "OK") {
          info();
          protectedAxiosIntance()
            .get(`courses/findCourse/${props.customer.id}`)
            .then((res) => {
              if (res.data.statusCode === "OK") {
                props.setCodes(res.data.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          info1();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [courseRequest, setCourseRequest] = useState({
    startDate: "",
    days: 90,
  });

  const handleDateChange = (name, date, dateString) => {
    setCourseRequest({ ...courseRequest, [name]: dateString });
  };
  const handleChange = (name, value) => {
    setCourseRequest((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Row className="tViewRowHeader">Codes</Row>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 2px #ddd",
          padding: "1rem",
          width: "100%",
          height: "auto",
          marginTop: "1rem",
        }}
      >
        {/* <span style={{fontWeight: '500', fontSize: '1.5rem'}}>Codes</span> */}
        {props.codes.length !== 0 && (
          <div style={{ paddingTop: "2rem", width: "100%" }}>
            <SidlabTable data={props.codes} cols={codesConstants()} />
          </div>
        )}
        <Modal title="Choose Your Start Date" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Form.Item
            className="required"
            name="startDate"
            rules={[
              {
                required: true,
                message: "Please input your start date",
              },
            ]}
          >
            <DatePicker
              name="startDate"
              placeholder="Start date"
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              onChange={(e) => {
                handleDateChange("startDate", e, e.format("DD/MM/YYYY"));
              }}
            />
          </Form.Item>
          <Form.Item
            name="numberOfDays"
            className="required"
            rules={[
              {
                required: true,
                message: "Please input the number of days",
              },
            ]}
          >
            <Input
              name="numberOfDays"
              placeholder="Number of days"
              defaultValue={90}
              onChange={(e) => {
                handleChange("days", e.target.value);
              }}
            />
          </Form.Item>
        </Modal>
        {props.adminRole && (
          <center>
            <Button
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "7px",
              }}
              type="primary"
              onClick={showModal}
            >
              Generate Course Code
            </Button>
          </center>
        )}
      </div>
    </>
  );
};

export default CustomerCourseCodes;
