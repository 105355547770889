import React from "react";
import { Row, Col } from "antd";
import styles from "../../../shared/table/Table.module.css";

const ReleasesTableHeader = (props) => {
  return (
    <Row className={styles.tHeader} style={{ width: "100%" }}>
      <Col className={styles.tHeaderCell} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        Name
      </Col>
      <Col className={styles.tHeaderCell} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        Release date
      </Col>
      <Col className={styles.tHeaderCell} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={5}>
        Download Links
      </Col>
      <Col className={styles.tHeaderCell} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} span={7}>
        Notes
      </Col>
      {props.adminRole && (
        <Col
          className={styles.tHeaderCell}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px" }}
          span={2}
        >
          Actions
        </Col>
      )}
    </Row>
  );
};

export default ReleasesTableHeader;
